import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { Bolt, Plus } from "lucide-react";
import React from "react";

type Props = {
  data: any;
  onUpdate: (formData: any) => void;
};

const QuickEntryForm = ({ data, onUpdate }: Props) => {
  const formik = useFormik({
    initialValues: data || {
      employeeNo: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      department: "",
      position: "",
      passport: "",
      ninId: "",
      country: "",
      dob: "",
      startDate: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => onUpdate(values),
  });

  React.useEffect(() => {
    let sub = true;

    if (sub) {
      onUpdate(formik.values);
    }

    return () => {
      sub = false;
    };
  }, [formik.values, onUpdate]);

  return (
    <form onSubmit={() => formik.handleSubmit}>
      <Grid container spacing={2}>
        <div className="w-full bg-[lightgray] py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
          <Bolt size={22} className="px-5" />
          <h3>In a rush? Just fill up this page and you are good to go.</h3>
        </div>

        {/* Employee Information */}
        <Grid item xs={12}>
          <TextField
            required
            autoFocus
            label="Employee ID"
            name="employeeNo"
            id="employeeNo"
            value={formik.values.employeeNo}
            onChange={formik.handleChange}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="First Name"
            placeholder="John"
            name="firstName"
            id="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Last Name"
            placeholder="Doe"
            name="lastName"
            id="lastName"
            type="text"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Date of Birth"
            name="dob"
            id="dob"
            type="date"
            fullWidth
            value={formik.values.dob}
            onChange={formik.handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Nationality"
            name="country"
            id="country"
            fullWidth
            value={formik.values.country}
            onChange={formik.handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="National ID"
            placeholder="CF01234456789"
            name="ninId"
            id="ninId"
            fullWidth
            value={formik.values.ninId}
            onChange={formik.handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Passport"
            name="passport"
            id="passport"
            type="text"
            fullWidth
            value={formik.values.passport}
            onChange={formik.handleChange}
            size="small"
          />
        </Grid>

        {/* Job Position */}
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <TextField
            label="Job Position"
            name="position"
            id="position"
            fullWidth
            value={formik.values.position}
            onChange={formik.handleChange}
            size="small"
          />

          <div className="p-2">
            <Plus size={22} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            label="Email Address"
            name="email"
            id="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default QuickEntryForm;
