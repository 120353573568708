import { Grid } from "@mui/material";
import React, { useState } from "react";

interface Module {
  id: number;
  name: string;
  enabled: boolean;
}

const Modules = () => {
  const [modules, setModules] = useState<Module[]>([
    { id: 1, name: "Expense Claim", enabled: false },
    { id: 2, name: "Leave", enabled: false },
    { id: 3, name: "Attendance", enabled: false },
  ]);

  const toggleModule = (id: number) => {
    setModules((prevModules) =>
      prevModules.map((module) =>
        module.id === id ? { ...module, enabled: !module.enabled } : module
      )
    );
  };

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-[lightgray] py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Modules Enabled for Employee Access</h3>
          </div>
        </Grid>
      </Grid>

      <div>
        {modules.map((module) => (
          <div
            key={module.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 0",
              borderBottom: "1px solid #eee",
            }}
          >
            <label style={{ cursor: "pointer", flexGrow: 1 }}>
              {module.name}
            </label>
            <input
              type="checkbox"
              checked={module.enabled}
              onChange={() => toggleModule(module.id)}
              style={{ cursor: "pointer" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Modules;
