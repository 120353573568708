export const COLORS = {
  primary: "#FF0000",
  secondary: "#00FF00",
  black: "#000000",
  success: "green",
  gray: "#808080",
  paramedic: {
    blue: "#0000FF",
    red: "#FF0000",
  },
};
