import { Event, RecordVoiceOver } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import AddIcon from "@mui/icons-material/Add";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const Incident = () => {
  const [open, setOpen] = useState(false);
  const [incidentDecision, setIncidentDecision] = useState("");
  const [category, setCategory] = useState("");
  const [incidentType, setIncidentType] = useState("");
  const [rule] = useState("N/A");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [summary, setSummary] = useState("");
  const [story, setStory] = useState("");
  const [status, setStatus] = useState("Not Started");
  const [isOpen, setIsOpen] = useState(false);
  const [explainBy] = useState("");
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setAttachment(event.target.files[0]);
    }
  };
  // const handleChange = (
  //   event: React.ChangeEvent<{ value: unknown }>,
  //   setter: React.Dispatch<React.SetStateAction<any>>
  // ) => {
  //   setter(event.target.value);
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attachment, setAttachment] = useState<File | null>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCategory(event.target.value);
  const handleIncidentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setIncidentType(event.target.value);
  const handleSummaryChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSummary(event.target.value);
  const handleStoryChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setStory(event.target.value);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(event.target.checked);
  };

  const [taxes] = useState<any[]>([]);

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-lightgray py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Incidents</h3>
          </div>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IconButton
            onClick={handleOpen}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.map((tax, index) => (
              <TableRow key={index}>
                <TableCell>{tax.code}</TableCell>
                <TableCell>{tax.description}</TableCell>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.isActive ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Incident</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Category"
            value={category}
            onChange={handleCategoryChange}
            select
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <AddCircleIcon color="action" />
                </IconButton>
              ),
            }}
          >
            <MenuItem value="Category 1">Category 1</MenuItem>
            <MenuItem value="Category 2">Category 2</MenuItem>
            <MenuItem value="Category 3">Category 3</MenuItem>
          </TextField>

          <TextField
            fullWidth
            label="Incident Type"
            value={incidentType}
            onChange={handleIncidentTypeChange}
            select
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <AddCircleIcon color="action" />
                </IconButton>
              ),
            }}
          >
            <MenuItem value="Incident 1">Incident 1</MenuItem>
            <MenuItem value="Incident 2">Incident 2</MenuItem>
            <MenuItem value="Incident 3">Incident 3</MenuItem>
          </TextField>

          <TextField
            fullWidth
            label="Rule"
            value={rule}
            disabled
            variant="outlined"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            fullWidth
            label="From"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            variant="outlined"
            margin="normal"
            InputProps={{
              startAdornment: (
                <IconButton>
                  <Event />
                </IconButton>
              ),
            }}
          />

          <TextField
            fullWidth
            label="To"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            variant="outlined"
            margin="normal"
            InputProps={{
              startAdornment: (
                <IconButton>
                  <Event />
                </IconButton>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Summary"
            value={summary}
            onChange={handleSummaryChange}
            variant="outlined"
            margin="normal"
            multiline
            rows={2}
            maxRows={4}
          />

          <TextField
            fullWidth
            label="Story"
            value={story}
            onChange={handleStoryChange}
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
            maxRows={6}
          />

          <FormControl fullWidth margin="normal">
            <Input
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: "*" }}
              endAdornment={
                <IconButton>
                  <AttachFileIcon color="action" />
                </IconButton>
              }
            />
          </FormControl>
          <DialogTitle>
            <IconButton edge="start" color="inherit" aria-label="record-voice">
              <RecordVoiceOver />
            </IconButton>
            Investigation
          </DialogTitle>
          <TextField
            fullWidth
            label="Status"
            value={status}
            onChange={handleStatusChange}
            select
            variant="outlined"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="Not Started">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
          </TextField>

          {/* Open Switch */}
          <FormControlLabel
            control={
              <Switch
                checked={isOpen}
                onChange={handleSwitchChange}
                name="open"
                color="primary"
              />
            }
            label="Open"
          />

          {/* Explain By Field (Disabled) */}

          <TextField
            fullWidth
            label="Explain By"
            type="date"
            value={explainBy}
            onChange={(e) => setFromDate(e.target.value)}
            variant="outlined"
            margin="normal"
            InputProps={{
              startAdornment: (
                <IconButton>
                  <Event />
                </IconButton>
              ),
            }}
          />
          <DialogTitle>Decision</DialogTitle>
          <TextField
            label="Incident Decision"
            value={incidentDecision}
            onChange={(e) => setIncidentDecision(e.target.value)}
            fullWidth
            select
            variant="outlined"
            margin="normal"
          >
            <MenuItem value="Open">Open</MenuItem>
            {/* Add more options here */}
          </TextField>

          {/* From Date */}
          <TextField
            label="From"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          {/* To Date */}
          <TextField
            label="To"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <DialogTitle>Management</DialogTitle>
          <TextField
            fullWidth
            label="Remarks"
            value={summary}
            onChange={handleSummaryChange}
            variant="outlined"
            margin="normal"
            multiline
            rows={2}
            maxRows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Incident;
