import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";
import "./assets/styles/tailwind.css";

// layouts

// views without layouts

ReactDOM.render(<App />, document.getElementById("root"));
