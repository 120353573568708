import Dashboard from "../views/admin/Dashboard";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import EmployeeCreationTabs from "../views/employee-management/EmployeeCreationTabs";
import EmployeeManagement from "../views/employee-management/EmployeeManagement";
import Department from "../views/employer-management/departments";
import JobPositions from "../views/employer-management/jobPositions";
import Level from "../views/employer-management/level";
import Modules from "../views/employer-management/modules";
import Incident from "../views/incident-management/Incident";
import IncidentCategory from "../views/incident-management/IncidentCategory";
import Index from "../views/Landing";
import { LeaveForm } from "../views/leave-management/configurations/leaveType";
import AnnualSalaryReport from "../views/payroll-management/AnnualSalaryReport";
import Bonus from "../views/payroll-management/Bonus";
import Deductions from "../views/payroll-management/Deductions";
import Earnings from "../views/payroll-management/Earnings";
import ProcessPayRoll from "../views/payroll-management/ProcessPayment";
import SalaryAdjustment from "../views/payroll-management/SalaryAdjustment";
import StatutoryContribution from "../views/payroll-management/StatutoryContribution";
import StatutoryTable from "../views/payroll-management/StatutoryTable";
import Profile from "../views/Profile";
import Annoucements from "../views/team-management/Announcements";
import Discussions from "../views/team-management/Discussion";
import DocumentSharing from "../views/team-management/DocumentSharingForm";
import { routings } from "./routings";

export const appRoutes = [
  {
    path: "/landing",
    Component: Index,
    title: "Registration",
    isPrivateRoute: false,
  },
  {
    path: "/user-profile",
    Component: Profile,
    title: "User Profile",
    isPrivateRoute: true,
  },
  {
    path: "/",
    Component: Index,
    title: "Home",
    isPrivateRoute: false,
  },
  {
    path: routings.register,
    Component: Register,
    title: "Sign Up",
    isPrivateRoute: false,
  },
  {
    path: routings.login,
    Component: Login,
    title: "Sign In",
    isPrivateRoute: false,
  },
  {
    path: routings.employee.management,
    Component: EmployeeManagement,
    title: "Employee Management",
    isPrivateRoute: true,
  },
  {
    path: routings.dashboard,
    Component: Dashboard,
    title: "Dashboard",
    isPrivateRoute: true,
  },
  {
    path: routings.employee.create,
    Component: EmployeeCreationTabs,
    title: "Employee Management",
    isPrivateRoute: true,
  },
  {
    path: routings.leave.leaveTypes,
    Component: LeaveForm,
    isPrivateRoute: true,
  },
  {
    path: routings.employer.department,
    Component: Department,
    isPrivateRoute: true,
  },
  {
    path: routings.employer.jobPositions,
    Component: JobPositions,
    isPrivateRoute: true,
  },
  {
    path: routings.employer.level,
    Component: Level,
    isPrivateRoute: true,
  },
  {
    path: routings.employer.modules,
    Component: Modules,
    isPrivateRoute: true,
  },
  {
    path: routings.payroll.statutoryTable,
    Component: StatutoryTable,
    isPrivateRoute: true,
  },

  {
    path: routings.payroll.statutoryContribution,
    Component: StatutoryContribution,
    isPrivateRoute: true,
  },
  {
    path: routings.payroll.bonus,
    Component: Bonus,
    isPrivateRoute: true,
  },
  {
    path: routings.payroll.deductions,
    Component: Deductions,
    isPrivateRoute: true,
  },
  {
    path: routings.payroll.earnings,
    Component: Earnings,
    isPrivateRoute: true,
  },
  {
    path: routings.payroll.annualSalaryReport,
    Component: AnnualSalaryReport,
    isPrivateRoute: true,
  },
  {
    path: routings.payroll.process,
    Component: ProcessPayRoll,
    isPrivateRoute: true,
  },
  {
    path: routings.payroll.salaryAdjustment,
    Component: SalaryAdjustment,
    isPrivateRoute: true,
  },
  {
    path: routings.team.discussion,
    Component: Discussions,
    isPrivateRoute: true,
  },
  {
    path: routings.team.documentSharing,
    Component: DocumentSharing,
    isPrivateRoute: true,
  },
  {
    path: routings.team.announcements,
    Component: Annoucements,
    isPrivateRoute: true,
  },
  {
    path: routings.incident.incident,
    Component: Incident,
    isPrivateRoute: true,
  },
  {
    path: routings.incident.incidentCategory,
    Component: IncidentCategory,
    isPrivateRoute: true,
  },
];
