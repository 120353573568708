import moment from "moment";
import { COLORS } from "../theme/theme";

export const mailValidation = (email: string) => {
  let reg = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w\w+)+$/;
  if (reg.test(email) === false) {
    return false;
  } else {
    return true;
  }
};

export const validatePhone = (phone: string) => {
  let reg = /^(\d{10}|\d{12})$/;
  if (reg.test(phone) === false) {
    return false;
  } else {
    return true;
  }
};

export const formatText = (text: string) => {
  return text.replace(/[^+\d]/g, "");
};

export const logout = (props: any) => {
  return props.navigation.navigate("SignIn");
};

export const ONE_SECOND_IN_MS = 1000;
export const VIBRATION_PATTERN = [
  0.25 * ONE_SECOND_IN_MS,
  0.25 * ONE_SECOND_IN_MS,
  0.25 * ONE_SECOND_IN_MS,
];

export const getStatusColor = (color: string) => {
  switch (color) {
    case "inactive":
      return COLORS.gray; // Green
    case "incomingrequest":
      return COLORS.paramedic.blue;

    case "requestride":
      return getRandomColor();

    case "arrived":
      return getRandomColor();

    case "enroute":
      return getRandomColor();

    case "active":
      return COLORS.secondary;
    default:
      return "#FFFFFF"; // White
  }
};

export const logDev = (value: any) => {
  if (__DEV__) {
    console.log(value);
  }
};

export const __DEV__ = process.env.NODE_ENV !== "production" ? true : false;

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatDate = (date: string) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const getRandomColor = (): string => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const currentTime = () => {
  const timer = setInterval(() => {
    return moment().format("HH:mm:ss");
  }, 1000);

  return () => clearInterval(timer);
};
