// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

type DialogContentProps = {
  open: any;
  onClose: any;
  onSubmitFormData: any;
  header: any;
  subHeader: any;
  children: any;
};
const CustomFormDialog = ({
  open,
  onClose,
  onSubmitFormData,
  header,
  subHeader,
  children,
}: DialogContentProps) => {
  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className="px-0 w-screen">{header}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {/* <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
};

export default CustomFormDialog;
