import { LOGIN_ADMIN_USER, LOGOUT } from "../types/authTypes";

export function loginAction(payload: any) {
  return {
    type: LOGIN_ADMIN_USER,
    payload: payload,
  };
}

export const logoutUser = () => {
  return {
    type: LOGOUT,
  };
};
