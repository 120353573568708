import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const StatutoryTable = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    description: "",
    isActive: false,
    type: "PAYE Tax",
    brackets: [{ incomeMoreThan: "", percentage: "", cumulativeTax: "" }],
  });
  const [taxes] = useState<any[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleToggleChange = () => {
    setFormData((prev) => ({ ...prev, isActive: !prev.isActive }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleAddTax = () => {
  //   setTaxes((prev) => [...prev, formData]);
  //   setFormData({
  //     code: "",
  //     description: "",
  //     isActive: false,
  //     type: "",
  //     brackets: [],
  //   });
  //   handleClose();
  // };

  const handleAddBracket = () => {
    setFormData({
      ...formData,
      brackets: [
        ...formData.brackets,
        { incomeMoreThan: "", percentage: "", cumulativeTax: "" },
      ],
    });
  };

  const handleRemoveBracket = (index: number) => {
    const updatedBrackets = formData.brackets.filter((_, i) => i !== index);
    setFormData({ ...formData, brackets: updatedBrackets });
  };

  const handleBracketChange = (index: number, field: string, value: string) => {
    const updatedBrackets = formData.brackets.map((bracket, i) =>
      i === index ? { ...bracket, [field]: value } : bracket
    );
    setFormData({ ...formData, brackets: updatedBrackets });
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-lightgray py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Statutory Taxes</h3>
          </div>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IconButton
            onClick={handleOpen}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.map((tax, index) => (
              <TableRow key={index}>
                <TableCell>{tax.code}</TableCell>
                <TableCell>{tax.description}</TableCell>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.isActive ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal to add tax */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Statutory Tax</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Code Input Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Code"
                name="code"
                variant="outlined"
                value={formData.code}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Description Input Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                variant="outlined"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Active Toggle */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.isActive}
                    onChange={handleToggleChange}
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>

            {/* Type Select Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Type"
                name="type"
                variant="outlined"
                value={formData.type}
                onChange={handleInputChange}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">Select Type</option>
                <option value="PAYE Tax">PAYE Tax</option>
                <option value="Other">Other</option>
              </TextField>
            </Grid>

            {/* Tax Brackets */}
            <Grid item xs={12}>
              <h3>Tax Brackets</h3>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddBracket}
              >
                Add Bracket
              </Button>
              <table>
                <thead>
                  <tr>
                    <th>Income More Than</th>
                    <th>%</th>
                    <th>Cumulative Tax</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.brackets.map((bracket, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          value={bracket.incomeMoreThan}
                          onChange={(e) =>
                            handleBracketChange(
                              index,
                              "incomeMoreThan",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={bracket.percentage}
                          onChange={(e) =>
                            handleBracketChange(
                              index,
                              "percentage",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={bracket.cumulativeTax}
                          onChange={(e) =>
                            handleBracketChange(
                              index,
                              "cumulativeTax",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleRemoveBracket(index)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {}} color="primary">
            Add Tax
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatutoryTable;
