import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const DocumentSharing = () => {
  const [open, setOpen] = useState(false);
  const [department, setDepartment] = useState("");
  const [branch, setBranch] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attachment, setAttachment] = useState<File | null>(null);
  const [content, setContent] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setAttachment(event.target.files[0]);
    }
  };

  const [selected, setSelected] = React.useState({
    lineManager: false,
    headOfDepartment: false,
    headOfBranch: false,
  });

  const handleToggle = (key: keyof typeof selected) => {
    setSelected((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [taxes] = useState<any[]>([]);

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-lightgray py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Document Sharing</h3>
          </div>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IconButton
            onClick={handleOpen}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.map((tax, index) => (
              <TableRow key={index}>
                <TableCell>{tax.code}</TableCell>
                <TableCell>{tax.description}</TableCell>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.isActive ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Document & Form Sharing</DialogTitle>
        <DialogContent>
          {/* Title Field */}
          <TextField
            fullWidth
            label={
              <>
                Title
                <i
                  style={{
                    fontSize: "10px",
                    color: "orange",
                    marginLeft: "5px",
                    verticalAlign: "text-top",
                  }}
                  className="material-icons"
                >
                  star
                </i>
              </>
            }
            variant="outlined"
          />
          {/* Attachment Input */}
          <FormControl fullWidth margin="normal">
            <Input
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: "*" }}
              endAdornment={
                <IconButton>
                  <AttachFileIcon color="action" />
                </IconButton>
              }
            />
          </FormControl>

          {/* Department Select */}
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>
              Category
              <i
                style={{
                  fontSize: "10px",
                  color: "orange",
                  marginLeft: "5px",
                  verticalAlign: "text-top",
                }}
                className="material-icons"
              >
                star
              </i>
            </InputLabel>
            <Select
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              label="Department"
              endAdornment={
                <IconButton>
                  <AddCircleIcon color="action" />
                </IconButton>
              }
            >
              <MenuItem value="HR">HR</MenuItem>
              <MenuItem value="Finance">Finance</MenuItem>
              <MenuItem value="Engineering">Engineering</MenuItem>
            </Select>
          </FormControl>
          <TextareaAutosize
            minRows={3}
            placeholder="Content (5000 characters max)"
            style={{
              width: "100%",
              marginTop: "16px",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
            maxLength={5000}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />

          {/* Branch Select */}
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>
              Scope
              <i
                style={{
                  fontSize: "10px",
                  color: "orange",
                  marginLeft: "5px",
                  verticalAlign: "text-top",
                }}
                className="material-icons"
              >
                star
              </i>
            </InputLabel>
            <Select
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
              label="Branch"
            >
              <MenuItem value="NYC">All Employees</MenuItem>
              <MenuItem value="LA">Employer</MenuItem>
              <MenuItem value="SF">Selected Employee</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>
              Employee
              <i
                style={{
                  fontSize: "10px",
                  color: "orange",
                  marginLeft: "5px",
                  verticalAlign: "text-top",
                }}
                className="material-icons"
              >
                star
              </i>
            </InputLabel>
            <Select
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
              label="Branch"
            >
              <MenuItem value="NYC">All Employees</MenuItem>
              <MenuItem value="LA">Employer</MenuItem>
              <MenuItem value="SF">Selected Employee</MenuItem>
            </Select>
          </FormControl>

          <DialogTitle style={{ backgroundColor: "#1976d2", color: "white" }}>
            Share With
          </DialogTitle>
          <List>
            {[
              { label: "Line Manager", key: "lineManager" },
              { label: "Head of Department", key: "headOfDepartment" },
              { label: "Head of Branch", key: "headOfBranch" },
            ].map((item) => (
              <ListItem
                key={item.key}
                onClick={() => handleToggle(item.key as keyof typeof selected)}
                style={{ cursor: "pointer" }}
              >
                <ListItemText primary={item.label} />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={selected[item.key as keyof typeof selected]}
                    onChange={() =>
                      handleToggle(item.key as keyof typeof selected)
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentSharing;
