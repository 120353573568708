/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown";
import UserDropdown from "../../components/Dropdowns/UserDropdown";
import { routings } from "../../routes/routings";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [openMenus, setOpenMenus] = React.useState<string[]>([]);
  type MenuItem = {
    title: string;
    path?: string;
    icon: string;
    active: boolean;
    children?: MenuItem[];
  };
  const items: MenuItem[] = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "fas fa-tachometer-alt mr-2", // Dashboard icon
      active: true,
    },
    {
      title: "Employee Management",
      icon: "fas fa-users mr-2", // Employee icon
      active: true,
      children: [
        {
          title: "Create Employee",
          path: routings.employee.management,
          icon: "fas fa-user-plus mr-2", // Create Employee icon
          active: true,
        },
        {
          title: "Request",
          path: routings.employee.management,
          icon: "fas fa-envelope-open-text mr-2", // Request icon
          active: true,
        },
        {
          title: "Placement",
          path: routings.employee.management,
          icon: "fas fa-clipboard-list mr-2", // Placement icon
          active: true,
        },
        {
          title: "Employment Terms",
          path: routings.employee.management,
          icon: "fas fa-file-alt mr-2", // Employment Terms icon
          active: true,
        },
        {
          title: "Education",
          path: routings.employee.management,
          icon: "fas fa-graduation-cap mr-2", // Education icon
          active: true,
        },
        {
          title: "Experience",
          path: routings.employee.management,
          icon: "fas fa-briefcase mr-2", // Experience icon
          active: true,
        },
        {
          title: "Training",
          path: routings.employee.management,
          icon: "fas fa-chalkboard-teacher mr-2", // Training icon
          active: true,
        },
        {
          title: "Custom Role",
          path: routings.employee.management,
          icon: "fas fa-cogs mr-2", // Custom Role icon
          active: true,
        },
      ],
    },
    {
      title: "Leave Management",
      path: routings.leave.leaveTypes,
      icon: "fas fa-calendar-day mr-2", // Leave Management icon
      active: true,
      children: [
        {
          title: "Planner",
          path: routings.employee.management,
          icon: "fas fa-calendar-check mr-2", // Planner icon
          active: true,
        },
        {
          title: "Schedule",
          path: routings.employee.management,
          icon: "fas fa-calendar-alt mr-2", // Schedule icon
          active: true,
        },
        {
          title: "Review",
          path: routings.employee.management,
          icon: "fas fa-clipboard-check mr-2", // Review icon
          active: true,
        },
        {
          title: "Transaction Report",
          path: routings.employee.management,
          icon: "fas fa-file-invoice mr-2", // Transaction Report icon
          active: true,
        },
        {
          title: "Entitlement Report",
          path: routings.employee.management,
          icon: "fas fa-file-contract mr-2", // Entitlement Report icon
          active: true,
        },
        {
          title: "Leave Type",
          path: routings.employee.management,
          icon: "fas fa-briefcase mr-2", // Leave Type icon
          active: true,
        },
        {
          title: "Earning Policy",
          path: routings.employee.management,
          icon: "fas fa-piggy-bank mr-2", // Earning Policy icon
          active: true,
        },
        {
          title: "Approval WorkFlow",
          path: routings.employee.management,
          icon: "fas fa-check-circle mr-2", // Approval Workflow icon
          active: true,
        },
        {
          title: "WorkDay",
          path: routings.employee.management,
          icon: "fas fa-business-time mr-2", // WorkDay icon
          active: true,
        },
        {
          title: "Holiday",
          path: routings.employee.management,
          icon: "fas fa-sun mr-2", // Holiday icon
          active: true,
        },
        {
          title: "Settings",
          path: routings.employee.management,
          icon: "fas fa-cogs mr-2", // Settings icon
          active: true,
        },
      ],
    },
    {
      title: "Attendance",
      icon: "fas fa-clock mr-2", // Attendance icon
      active: true,
      children: [
        {
          title: "Create Employee",
          path: routings.employee.management,
          icon: "fas fa-user-plus mr-2", // Create Employee icon
          active: true,
        },
        {
          title: "Field CheckIn",
          path: routings.employee.management,
          icon: "fas fa-map-marker-alt mr-2", // Field CheckIn icon
          active: true,
        },
        {
          title: "Time Clock Report",
          path: routings.employee.management,
          icon: "fas fa-clock mr-2", // Time Clock Report icon
          active: true,
        },
        {
          title: "WorkDay",
          path: routings.employee.management,
          icon: "fas fa-business-time mr-2", // WorkDay icon
          active: true,
        },
        {
          title: "Holiday",
          path: routings.employee.management,
          icon: "fas fa-sun mr-2", // Holiday icon
          active: true,
        },
        {
          title: "Settings",
          path: routings.employee.management,
          icon: "fas fa-cogs mr-2", // Settings icon
          active: true,
        },
      ],
    },
    {
      title: "Document WorkFlow",
      icon: "fas fa-file-alt mr-2", // Document Workflow icon
      active: true,
      children: [
        {
          title: "Management",
          path: routings.employee.management,
          icon: "fas fa-tasks mr-2", // Management icon
          active: true,
        },
        {
          title: "Review",
          path: routings.employee.management,
          icon: "fas fa-check-circle mr-2", // Review icon
          active: true,
        },
        {
          title: "Approval WorkFlow",
          path: routings.employee.management,
          icon: "fas fa-check-circle mr-2", // Approval Workflow icon
          active: true,
        },
      ],
    },
    {
      title: "Incident",
      icon: "fas fa-exclamation-triangle mr-2", // Incident icon
      active: true,
      children: [
        {
          title: "Management",
          path: routings.incident.incident,
          icon: "fas fa-cogs mr-2", // Management icon
          active: true,
        },
        {
          title: "Causeless",
          path: routings.employee.management,
          icon: "fas fa-bell mr-2", // Causeless icon
          active: true,
        },
        {
          title: "Category",
          path: routings.incident.incidentCategory,
          icon: "fas fa-tags mr-2", // Category icon
          active: true,
        },
        {
          title: "Type",
          path: routings.employee.management,
          icon: "fas fa-th-list mr-2", // Type icon
          active: true,
        },
        {
          title: "Decision",
          path: routings.employee.management,
          icon: "fas fa-check mr-2", // Decision icon
          active: true,
        },
      ],
    },
    {
      title: "Team",
      icon: "fas fa-users mr-2", // Team icon
      active: true,
      children: [
        {
          title: "Discussions",
          path: routings.team.discussion,
          icon: "fas fa-comments mr-2", // Discussions icon
          active: true,
        },
        {
          title: "Document & Form Sharing",
          path: routings.team.documentSharing,
          icon: "fas fa-share-square mr-2", // Document & Form Sharing icon
          active: true,
        },
        {
          title: "Announcements",
          path: routings.team.announcements,
          icon: "fas fa-bullhorn mr-2", // Announcements icon
          active: true,
        },
      ],
    },
    {
      title: "Payroll",
      icon: "fas fa-money-check-alt mr-2", // Payroll icon
      active: true,
      children: [
        {
          title: "Salary Adjustment",
          path: routings.payroll.salaryAdjustment,
          icon: "fas fa-adjust mr-2", // Salary Adjustment icon
          active: true,
        },
        {
          title: "Process",
          path: routings.payroll.process,
          icon: "fas fa-play-circle mr-2", // Process icon
          active: true,
        },
        {
          title: "Annual Salary Statement",
          path: routings.payroll.annualSalaryReport,
          icon: "fas fa-file-invoice-dollar mr-2", // Annual Salary Statement icon
          active: true,
        },
        {
          title: "Earning",
          path: routings.payroll.earnings,
          icon: "fas fa-money-bill-wave mr-2", // Earning icon
          active: true,
        },
        {
          title: "Deductions",
          path: routings.payroll.deductions,
          icon: "fas fa-credit-card mr-2", // Deductions icon
          active: true,
        },
        {
          title: "Bonus",
          path: routings.payroll.bonus,
          icon: "fas fa-gift mr-2", // Bonus icon
          active: true,
        },
        {
          title: "Statutory Contribution",
          path: routings.payroll.statutoryContribution,
          icon: "fas fa-hand-holding-usd mr-2", // Statutory Contribution icon
          active: true,
        },
        {
          title: "Statutory Table",
          path: routings.payroll.statutoryTable,
          icon: "fas fa-table mr-2", // Statutory Table icon
          active: true,
        },
        {
          title: "Setting",
          path: routings.employee.management,
          icon: "fas fa-cogs mr-2", // Setting icon
          active: true,
        },
      ],
    },
    {
      title: "Employer",
      icon: "fas fa-building mr-2", // Employer icon
      active: true,
      children: [
        {
          title: "Information",
          path: routings.employee.management,
          icon: "fas fa-info-circle mr-2", // Information icon
          active: true,
        },
        {
          title: "Job Position",
          path: routings.employer.jobPositions,
          icon: "fas fa-briefcase mr-2", // Job Position icon
          active: true,
        },
        {
          title: "Department",
          path: routings.employer.department,
          icon: "fas fa-sitemap mr-2", // Department icon
          active: true,
        },
        {
          title: "Branch",
          path: routings.employee.management,
          icon: "fas fa-home mr-2", // Branch icon
          active: true,
        },
        {
          title: "Level",
          path: routings.employer.level,
          icon: "fas fa-level-up-alt mr-2", // Level icon
          active: true,
        },
        {
          title: "Bank",
          path: routings.employee.management,
          icon: "fas fa-university mr-2", // Bank icon
          active: true,
        },
        {
          title: "Setting",
          path: routings.employee.management,
          icon: "fas fa-cogs mr-2", // Setting icon
          active: true,
        },
        {
          title: "Module Access",
          path: routings.employer.modules,
          icon: "fas fa-lock mr-2", // Module Access icon
          active: true,
        },
      ],
    },
  ];

  const toggleMenu = (title: string) => {
    setOpenMenus(
      (prevOpenMenus) =>
        prevOpenMenus.includes(title)
          ? prevOpenMenus.filter((menu) => menu !== title) // Close the menu
          : [...prevOpenMenus, title] // Open the menu
    );
  };

  const renderSideBarItems = (menuItems = items) => {
    return menuItems.map((item, index) => (
      <div key={index}>
        <Link
          className={
            "block w-full whitespace-no-wrap px-6 py-2 text-sm font-bold text-blueGray-800 hover:text-blueGray-600 focus:outline-none focus:text-blueGray-600" +
            (item.active ? " active" : "")
          }
          onClick={() => {
            if (item.children) {
              toggleMenu(item.title);
            }
          }}
          to={item.path}
        >
          <i
            className={`${item.icon} text-sm ${
              window.location.href.indexOf(item.path) !== -1
                ? "opacity-75"
                : "text-blueGray-300"
            }`}
          ></i>{" "}
          {item.title}
        </Link>
        {item.children && openMenus.includes(item.title) && (
          <div className="ml-4">
            {renderSideBarItems(item.children)}{" "}
            {/* Recursively render children */}
          </div>
        )}
      </div>
    ));
  };

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-[whitesmoke] flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            Cantreet developers
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Search */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />

            {/* Render sidebar items */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">{renderSideBarItems()}</li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
          </div>
        </div>
      </nav>
    </>
  );
}
