import { Bolt, ChevronDown, Plus } from "lucide-react";
import React, { useState } from "react";

interface SpouseFormData {
  maritalStatus: "Single" | "Married" | "Divorced" | "Widowed";
  spouseWorking: boolean;
  spouse: {
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    nationality: string;
    nationalId: string;
    passport: string;
    ethnicity: string;
    religion: string;
  };
}

const initialSpouseData: SpouseFormData = {
  maritalStatus: "Single",
  spouseWorking: false,
  spouse: {
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    nationality: "",
    nationalId: "",
    passport: "",
    ethnicity: "",
    religion: "",
  },
};

interface TextFieldProps {
  label: string;
  value: string | number;
  onChange: (value: string) => void;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  min?: number;
  max?: number;
  rightIcon?: React.ReactNode;
}

function TextField({
  label,
  value,
  onChange,
  type = "text",
  disabled = false,
  placeholder,
  min,
  max,
  rightIcon,
}: TextFieldProps) {
  return (
    <div className="relative">
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        min={min}
        max={max}
        className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
      />
      {rightIcon && (
        <div className="absolute right-3 top-1/2 -translate-y-1/2">
          {rightIcon}
        </div>
      )}
    </div>
  );
}

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  value: string;
  onChange: (value: string) => void;
  options: SelectOption[];
  label: string;
  disabled?: boolean;
}

function Select({
  value,
  onChange,
  options,
  label,
  disabled = false,
}: SelectProps) {
  return (
    <div className="relative">
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        className="w-full px-4 py-2.5 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ChevronDown
        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none"
        size={20}
      />
    </div>
  );
}

export default function FamilyForm() {
  const [formData, setFormData] = useState<SpouseFormData>(initialSpouseData);
  const [numberOfChildren, setNumberOfChildren] = useState(0);

  const isSpouseFieldsEnabled = formData.maritalStatus === "Married";

  const handleSpouseChange = (field: string, value: string | boolean) => {
    if (field === "spouseWorking") {
      setFormData({ ...formData, spouseWorking: value as boolean });
    } else {
      setFormData({
        ...formData,
        spouse: { ...formData.spouse, [field]: value },
      });
    }
  };

  const handleMaritalStatusChange = (value: string) => {
    setFormData({
      ...formData,
      maritalStatus: value as SpouseFormData["maritalStatus"],
    });
  };

  const handleNumberOfChildrenChange = (value: string) => {
    const num = parseInt(value) || 0;
    setNumberOfChildren(Math.min(30, Math.max(0, num)));
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6">
      {/* Header Alert */}
      <div className="w-full bg-yellow-100 py-2 px-3 rounded-lg flex items-center justify-center">
        <Bolt size={22} className="mr-3" />
        <h3 className="text-gray-800">
          In a rush? Just fill up this page and you are good to go.
        </h3>
      </div>

      {/* Spouse Section */}
      <div className="bg-white rounded-lg shadow-sm">
        <div className="border-b border-gray-200 px-6 py-4">
          <h2 className="text-lg font-semibold text-gray-900">Spouse</h2>
        </div>

        <div className="p-6 space-y-6">
          {/* Marital Status */}
          <Select
            value={formData.maritalStatus}
            onChange={handleMaritalStatusChange}
            options={[
              { value: "Single", label: "Single" },
              { value: "Married", label: "Married" },
              { value: "Divorced", label: "Divorced" },
              { value: "Widowed", label: "Widowed" },
            ]}
            label="Marital Status"
          />

          {/* Spouse Working Toggle */}
          <div className="flex items-center justify-between py-2 px-4 bg-gray-50 rounded-lg">
            <span className="text-gray-700">Spouse Working</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                disabled={!isSpouseFieldsEnabled}
                checked={formData.spouseWorking}
                onChange={(e) =>
                  handleSpouseChange("spouseWorking", e.target.checked)
                }
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>

          {/* Name Fields */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <TextField
              label="First Name"
              value={formData.spouse.firstName}
              onChange={(value) => handleSpouseChange("firstName", value)}
              disabled={!isSpouseFieldsEnabled}
            />
            <TextField
              label="Middle Name"
              value={formData.spouse.middleName}
              onChange={(value) => handleSpouseChange("middleName", value)}
              disabled={!isSpouseFieldsEnabled}
            />
            <TextField
              label="Last Name"
              value={formData.spouse.lastName}
              onChange={(value) => handleSpouseChange("lastName", value)}
              disabled={!isSpouseFieldsEnabled}
            />
          </div>

          {/* Other Fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <TextField
              type="date"
              label="Birth Date"
              value={formData.spouse.birthDate}
              onChange={(value) => handleSpouseChange("birthDate", value)}
              disabled={!isSpouseFieldsEnabled}
            />
            <TextField
              label="Nationality"
              value={formData.spouse.nationality}
              onChange={(value) => handleSpouseChange("nationality", value)}
              disabled={!isSpouseFieldsEnabled}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <TextField
              label="National ID"
              value={formData.spouse.nationalId}
              onChange={(value) => handleSpouseChange("nationalId", value)}
              disabled={!isSpouseFieldsEnabled}
            />
            <TextField
              label="Passport"
              value={formData.spouse.passport}
              onChange={(value) => handleSpouseChange("passport", value)}
              disabled={!isSpouseFieldsEnabled}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <TextField
              label="Ethnicity"
              value={formData.spouse.ethnicity}
              onChange={(value) => handleSpouseChange("ethnicity", value)}
              disabled={!isSpouseFieldsEnabled}
              rightIcon={<Plus size={20} className="text-amber-500" />}
            />
            <TextField
              label="Religion"
              value={formData.spouse.religion}
              onChange={(value) => handleSpouseChange("religion", value)}
              disabled={!isSpouseFieldsEnabled}
              rightIcon={<Plus size={20} className="text-amber-500" />}
            />
          </div>
        </div>
      </div>

      {/* Children Section */}
      <div className="bg-white rounded-lg shadow-sm">
        <div className="border-b border-gray-200 px-6 py-4">
          <h2 className="text-lg font-semibold text-gray-900">Children</h2>
        </div>

        <div className="p-6">
          <div className="relative">
            <TextField
              type="number"
              label="Number of Children"
              value={numberOfChildren}
              onChange={handleNumberOfChildrenChange}
              min={0}
              max={30}
              placeholder="0-30"
              rightIcon={
                <button className="p-1 rounded-full bg-amber-500 text-white hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2">
                  <Plus size={20} />
                </button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
