// import { configureStore } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
import userDataReducer from "../reducers/authReducer";

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware: any) =>
//     getDefaultMiddleware().concat(thunk),
// });

// export default store;

import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  userData: persistReducer(persistConfig, userDataReducer),
  // notifications: notificationReducer,
});

// Correct middleware application
export const store: any = createStore(rootReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
