import { ColorLens } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const IncidentCategory = () => {
  const [code, setCode] = useState("");
  const [open] = useState(false);
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [color, setColor] = useState("#F50000"); // Default to red

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Custom Dialog</DialogTitle>
      <DialogContent>
        {/* Code Input */}
        <TextField
          label="Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          variant="outlined"
          margin="normal"
          inputProps={{ maxLength: 20 }}
          required
        />

        {/* Description Input */}
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          variant="outlined"
          margin="normal"
          inputProps={{ maxLength: 50 }}
          required
        />

        {/* Active Toggle Switch */}
        <FormControlLabel
          control={
            <Switch
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
            />
          }
          label="Active"
        />

        {/* Dynamic Color Picker using <input type="color" /> */}
        <Box mt={2}>
          <input
            type="color"
            value={color}
            onChange={handleColorChange}
            style={{ width: "100%", height: "50px", border: "none" }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <IconButton
          aria-label="color"
          style={{ color }}
          onClick={() => {}}
          disabled
        >
          <ColorLens />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default IncidentCategory;
