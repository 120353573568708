import {
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {};

const SalaryForm = (props: Props) => {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label>Effective Date</label>
          <TextField
            required
            name="dob"
            id="dob"
            type="date"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            label="Basic Salary"
            name="levelId"
            type="number"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              Currency
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Next Review Date</label>
          <TextField
            required
            name="dob"
            id="dob"
            type="date"
            fullWidth
            size="small"
          />
        </Grid>

        {/* Expansion Header */}
        <Grid item xs={12} className="q-expansion-item">
          <Grid
            container
            alignItems="center"
            onClick={handleExpandClick}
            sx={{
              cursor: "pointer",
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: expanded ? "#e3f2fd" : "#f9f9f9",
              "&:hover": { backgroundColor: "#bbdefb" },
            }}
          >
            {/* Toggle Icon */}
            <Grid item>
              <IconButton>
                <ExpandMoreIcon
                  sx={{
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.2s",
                  }}
                />
              </IconButton>
            </Grid>

            {/* Title */}
            <Grid item xs>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                Earning
              </Typography>
            </Grid>

            {/* Add Button */}
            <Grid item>
              <IconButton
                sx={{
                  backgroundColor: "#FFC107",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#FFB300" },
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent collapse toggle when button is clicked
                  alert("Add button clicked");
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {/* Expansion Content */}
        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Card variant="outlined" sx={{ borderRadius: "8px" }}>
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  N/A
                </Typography>
              </CardContent>
            </Card>
          </Collapse>
        </Grid>

        {/* Expansion Header */}
        <Grid item xs={12} className="q-expansion-item">
          <Grid
            container
            alignItems="center"
            onClick={handleExpandClick}
            sx={{
              cursor: "pointer",
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: expanded ? "#e3f2fd" : "#f9f9f9",
              "&:hover": { backgroundColor: "#bbdefb" },
            }}
          >
            {/* Toggle Icon */}
            <Grid item>
              <IconButton>
                <ExpandMoreIcon
                  sx={{
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.2s",
                  }}
                />
              </IconButton>
            </Grid>

            {/* Title */}
            <Grid item xs>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                Deductions
              </Typography>
            </Grid>

            {/* Add Button */}
            <Grid item>
              <IconButton
                sx={{
                  backgroundColor: "#FFC107",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#FFB300" },
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent collapse toggle when button is clicked
                  alert("Add button clicked");
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {/* Expansion Content */}
        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Card variant="outlined" sx={{ borderRadius: "8px" }}>
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  N/A
                </Typography>
              </CardContent>
            </Card>
          </Collapse>
        </Grid>

        {/* Expansion Header */}
        <Grid item xs={12} className="q-expansion-item">
          <Grid
            container
            alignItems="center"
            onClick={handleExpandClick}
            sx={{
              cursor: "pointer",
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: expanded ? "#e3f2fd" : "#f9f9f9",
              "&:hover": { backgroundColor: "#bbdefb" },
            }}
          >
            {/* Toggle Icon */}
            <Grid item>
              <IconButton>
                <ExpandMoreIcon
                  sx={{
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.2s",
                  }}
                />
              </IconButton>
            </Grid>

            {/* Title */}
            <Grid item xs>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                Bonus
              </Typography>
            </Grid>

            {/* Add Button */}
            <Grid item>
              <IconButton
                sx={{
                  backgroundColor: "#FFC107",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#FFB300" },
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent collapse toggle when button is clicked
                  alert("Add button clicked");
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {/* Expansion Content */}
        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Card variant="outlined" sx={{ borderRadius: "8px" }}>
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  N/A
                </Typography>
              </CardContent>
            </Card>
          </Collapse>
        </Grid>

        {/* Expansion Header */}
        <Grid item xs={12} className="q-expansion-item">
          <Grid
            container
            alignItems="center"
            onClick={handleExpandClick}
            sx={{
              cursor: "pointer",
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: expanded ? "#e3f2fd" : "#f9f9f9",
              "&:hover": { backgroundColor: "#bbdefb" },
            }}
          >
            {/* Toggle Icon */}
            <Grid item>
              <IconButton>
                <ExpandMoreIcon
                  sx={{
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.2s",
                  }}
                />
              </IconButton>
            </Grid>

            {/* Title */}
            <Grid item xs>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                Statutory Contribution
              </Typography>
            </Grid>

            {/* Add Button */}
            <Grid item>
              <IconButton
                sx={{
                  backgroundColor: "#FFC107",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#FFB300" },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  alert("Add button clicked");
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Card variant="outlined" sx={{ borderRadius: "8px" }}>
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  N/A
                </Typography>
              </CardContent>
            </Card>
          </Collapse>
        </Grid>

        <h3>Employment terms</h3>

        <Grid item xs={12}>
          <label>Bank</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
              endAdornment: (
                <button
                  style={{
                    marginLeft: "8px",
                    padding: "4px 8px",
                    fontSize: "small",
                    cursor: "pointer",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  onClick={() => alert("Add Job Position")}
                >
                  Add
                </button>
              ),
            }}
          >
            <option value="" disabled>
              Select Bank
            </option>
            <option value="developer">Centenary Bank</option>
            <option value="designer">IMB Bank</option>
            <option value="manager">Stanbic Bank</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Bank Account</label>
          <TextField
            required
            name="dob"
            id="dob"
            type="text"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <label>Pay Period</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              Select Pay Period
            </option>
            <option value="developer">Monthly</option>
            <option value="designer">Weekly</option>
            <option value="manager">Semi-Monthly</option>
            <option value="manager">Bi-Weekly</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Method</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              Select Method
            </option>
            <option value="developer">Cash</option>
            <option value="designer">Cheque</option>
            <option value="manager">Bank</option>
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default SalaryForm;
