import { Dot, Search } from "lucide-react";
import React from "react";

type Props = {};

const CustomSearchEngine = (props: Props) => {
  return (
    <div className="border !rounded-2xl w-full p-2 flex justify-between">
      <div>
        <Dot />
      </div>
      <div>
        <input
          type="search"
          name="search"
          id="search"
          className="border-none "
        />
      </div>
      <div>
        <Search />
      </div>
    </div>
  );
};

export default CustomSearchEngine;
