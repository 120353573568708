import { FormikValues, useFormik } from "formik";
import { Flag, Key, Mail, User, Users } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomAutoComplete from "../../components/Inputs/CustomAutoComplete";
import CustomTextInput from "../../components/Inputs/CustomTextInput";
import Navbar from "../../components/Navbars/AuthNavbar";
import { useTitle } from "../../hooks/useTitle";
import { routings } from "../../routes/routings";
import { makeServerRequestWithoutToken } from "../../services/authService";

const initialValues = {
  firstName: "",
  lastName: "",
  otherNames: "",
  email: "",
  password: "",
  confirmPassword: "",
  agreeTsAndCs: false,
  confirmEmail: "",
  country: "",
};

export default function Register() {
  const [userType, setUserType] = useState({
    selected: true,
    type: "",
  });
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => register(values),
  });

  useTitle("Sign Up");

  const register = async (values: FormikValues) => {
    console.log(values.email, values.confirmEmail);

    if (values.email !== values.confirmEmail) {
      alert("Emails don't match. Please try again.");
      return;
    }
    try {
      const formData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        country: values.country,
        agreeTsAndCs: values.agreeTsAndCs,
        role: userType.type === "employee" ? "employee" : "admin",
      };

      const { returnCode, returnMessage } = await makeServerRequestWithoutToken(
          "auth",
          "create-user",
          JSON.stringify(formData)
      );

      if (returnCode === 201) {
        alert(returnMessage);
        navigate(routings.login);
      } else if (returnCode === 409) {
        alert("Email already exists. Please try again with another email.");
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  return (
      <>
        <Navbar />
        <div className="mx-auto my-16 w-screen min-h-screen h-auto bg-blueGray-800">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full sm:w-7/12 md:w-5/12 lg:w-6/12 px-4">
              <div
                  className={`flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 ${
                      userType.type === "employee"
                          ? "items-center justify-center"
                          : "relative mt-16"
                  }`}
              >
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Register into the HR system as a specified user
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    <button
                        className={`${
                            userType.type === "admin"
                                ? "bg-blueGray-800 text-white"
                                : "bg-white active:bg-blueGray-50 text-blueGray-700"
                        } px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150`}
                        onClick={() =>
                            setUserType({ selected: true, type: "admin" })
                        }
                    >
                      <User />
                      Administrator
                    </button>
                    <button
                        className={`${
                            userType.type === "employee"
                                ? "bg-blueGray-800 text-white"
                                : "bg-white active:bg-blueGray-50 text-blueGray-700"
                        } px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150`}
                        onClick={() =>
                            setUserType({ selected: true, type: "employee" })
                        }
                    >
                      <Users />
                      Employee
                    </button>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="text-blueGray-400 text-center mb-3 font-bold">
                    <small>Or sign up with credentials</small>
                  </div>
                  {userType.type === "admin" ? (
                      <form onSubmit={formik.handleSubmit} method="POST">
                        <div className="relative w-full mb-3">
                          <CustomTextInput
                              id="firstName"
                              name="firstName"
                              placeholder="John"
                              label="First Name"
                              onChange={formik.handleChange}
                              value={formik.values.firstName}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <CustomTextInput
                              id="lastName"
                              name="lastName"
                              placeholder="Doe"
                              label="Last Name"
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <CustomTextInput
                              id="email"
                              name="email"
                              placeholder="janedoe@gmail.com"
                              label="Email Address"
                              icon={Mail}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <CustomTextInput
                              id="confirmEmail"
                              name="confirmEmail"
                              placeholder="janedoe@gmail.com1"
                              label="Confirm Email Address"
                              icon={Mail}
                              onChange={formik.handleChange}
                              value={formik.values.confirmEmail}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <CustomTextInput
                              id="password"
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              label="Password"
                              icon={Key}
                              onChange={formik.handleChange}
                              value={formik.values.password}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <CustomAutoComplete
                              id="country"
                              name="country"
                              type="text"
                              placeholder="Select Country of Origin"
                              label="Select Country"
                              onChange={formik.handleChange}
                              value={formik.values.country}
                              icon={Flag}
                              items={[]}
                          />
                        </div>

                        <div>
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                                id="agreeTsAndCs"
                                name="agreeTsAndCs"
                                onChange={formik.handleChange}
                                type="checkbox"
                                checked={formik.values.agreeTsAndCs}
                            />
                            <span className="ml-2 text-sm font-semibold text-blueGray-600">
                          I agree with the{" "}
                              <a
                                  href="#pablo"
                                  className="text-lightBlue-500"
                                  onClick={(e) => e.preventDefault()}
                              >
                            Privacy Policy
                          </a>
                        </span>
                          </label>
                        </div>

                        <div className="text-center mt-6">
                          <button
                              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                              type="submit"
                          >
                            Create Account
                          </button>

                          <div className="py-3 flex justify-center space-x-3">
                            <h3 className="capitalize text-base">
                              Already have an account?{" "}
                            </h3>
                            <button
                                onClick={() => navigate(routings.login)}
                                className="hover:font-semibold capitalize"
                            >
                              Click here
                            </button>
                          </div>
                        </div>
                      </form>
                  ) : (
                      <>
                        <h3 className="text-center px-2 py-4">
                          Did your Cantreet ask you to sign up for an{" "}
                          <span className="font-bold">Employee Web Account</span>?
                          You may do so by clicking at the sign up link in the{" "}
                          <span className="font-bold">Invitation Email</span> that
                          your Cantreet Administrator sent to you.
                        </h3>

                        <h3 className="text-center px-2 py-4">
                          If you still haven't received your invitation email,
                          please check your junk mail folder or ask your Cantreet
                          Administrator to invite you again.
                        </h3>
                      </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
