import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const JobPositions = () => {
  const [departments, setDepartments] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    name: "",
    responsibilities: "",
    active: true,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleToggleChange = () => {
    setFormData({
      ...formData,
      active: !formData.active,
    });
  };

  const handleSubmit = () => {
    setDepartments([...departments, formData]);
    setOpen(false);
    setFormData({ code: "", name: "", responsibilities: "", active: true });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({ code: "", name: "", responsibilities: "", active: true });
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-[lightgray] py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Job Positions</h3>
          </div>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IconButton
            onClick={handleOpen}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Head</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.map((department, index) => (
              <TableRow key={index}>
                <TableCell>{department.code}</TableCell>
                <TableCell>{department.name}</TableCell>
                <TableCell>{department.head}</TableCell>
                <TableCell>{department.active ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal to add department */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Job Position</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Code Input Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Code"
                id="code"
                name="code"
                variant="outlined"
                value={formData.code}
                onChange={handleChange}
                required
              />
            </Grid>

            {/* Name Input Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                id="name"
                name="name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>

            {/* Active Toggle */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.active}
                    onChange={handleToggleChange}
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>

            {/* Head Select Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Job Responsibilities"
                id="responsibilities"
                name="responsibilities"
                variant="outlined"
                value={formData.responsibilities}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add JobPositions
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobPositions;
