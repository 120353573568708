import { LucideIcon } from "lucide-react"; // For the optional icon prop
import React from "react";

interface Props {
  label: string;
  type?: string;
  className?: string;
  placeholder: string;
  containerStyle?: string;
  id?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  icon?: LucideIcon; // Optional icon
}

const CustomTextInput = ({
  label,
  className = "",
  placeholder,
  type = "text",
  containerStyle = "",
  name = label || "text-input",
  id = label || "grid-input",
  onChange,
  value,
  icon: IconComponent,
}: Props) => {
  return (
    <div className={`flex flex-col w-full ${containerStyle}`}>
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor={label}
      >
        {label}
      </label>
      <div className="flex items-center bg-white rounded text-sm shadow px-3 h-12">
        {IconComponent && (
          <div className="pr-4">
            <IconComponent size={24} />
          </div>
        )}
        <input
          id={id}
          type={type}
          name={name}
          onChange={onChange}
          value={value}
          className={`border-0 placeholder-blueGray-300 text-blueGray-600 focus:outline-none w-full focus:ring ease-linear transition-all duration-150 h-full ${className}`}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default CustomTextInput;
