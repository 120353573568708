import { LucideIcon } from "lucide-react"; // For the optional icon prop
import React from "react";

type Props = {
  id: string;
  name: string;
  value: string;
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  icon?: LucideIcon; // Optional icon
  items: { id: string; name: string }[];
  onSelectItem?: (item: any) => void;
  label?: string; // Optional label for the input field
};

const CustomAutoComplete = ({
  id,
  name,
  value,
  type = "text",
  onChange,
  placeholder,
  items,
  icon: IconComponent,
  onSelectItem,
  label,
}: Props) => {
  return (
    <div className="flex flex-col w-full">
      <label htmlFor={label}>{label}</label>
      <div className="flex items-center bg-white rounded text-sm shadow px-3 h-12">
        {IconComponent && (
          <div className="pr-4">
            <IconComponent size={24} />
          </div>
        )}
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="border-0 placeholder-blueGray-300 text-blueGray-600 focus:outline-none w-full focus:ring ease-linear transition-all duration-150 h-full"
        />
        {items.length > 0 && (
          <ul
            id="conincidences_list"
            className="bg-white border rounded-lg shadow-lg p-4 absolute max-h-[200px] overflow-y-auto"
          >
            {items.map((item) => (
              <li
                key={item.id}
                onClick={onSelectItem}
                className="min-h-10 w-full border-b border-solid border-coolGray-400 py-2"
              >
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomAutoComplete;
