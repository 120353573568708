import * as React from "react";

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [theme, setTheme] = React.useState("light");
  const [user, setUser] = React.useState(null);
  const toggleTheme = () => setTheme(theme === "light" ? "dark" : "light");

  return (
    <AppContext.Provider
      value={{ theme, setTheme, toggleTheme, user, setUser }}
    >
      {children}
    </AppContext.Provider>
  );
};
