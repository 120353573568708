import axios from "axios";
import { __DEV__, logDev } from "./CommonService";
import http from "./httpService";

/**
 * HTTP connection Urls
 * */
// export const url = 'http://192.168.1.13:8086/';
// export const live_url = 'https://cantreetdevs/';
export const live_url = "https://cantreetdevs.org/cantreet-ug/";
export const local_url = "http://localhost:8083/";
export const test_url = "https://cantreetdevs.org/cantreet-test/";

export const url = __DEV__ ? local_url : test_url;

/**
 * WebSocket connection Urls
//  * */
// export const wss_live_url =
//   "https://https://cantreetdevs/ws-message";
// export const wss_live_url = 'https://cantreetdevs.org/cantreet-ug/ws-message';
export const ws_local_url = "http://localhost:8083/ws-message";

// export const ws_url = ws_local_url : wss_live_url;

export async function loginUser(
  requestMapping: string,
  controller: string,
  rowData: any
) {
  try {
    // Clearing the old auth token before logging in
    delete axios.defaults.headers.common.Authorization;

    const requestUrl = `${url}${requestMapping}/${controller}`;

    const { data } = await http.post(requestUrl, rowData);

    return data;
  } catch (e: any) {
    console.log(e);

    throw e;
  }
}

export async function logOutUser(userId: number) {
  try {
    makeServerRequestWithoutToken("auth", "logout-user", {
      id: userId,
    });
  } catch (error: any) {
    logDev(error.message);
  }
}

export const makeServerRequest = async (
  requestMapping: string,
  controller: string,
  rowData: any,
  token?: string
) => {
  // const state = await NetInfo.fetch();
  // if (!state.isConnected) {
  //   // Handle no internet connection
  //   console.error('No internet connection');
  //   logDevWithoutVibration('No internet connection');
  //   return;
  // }

  try {
    const requestUrl = `${url}${requestMapping}/${controller}`;
    // const token = localStorage?.getItem("token");

    if (!token) {
      console.error("Token is not available");
      logDev("Unable to make a request");
      return;
    }

    const { data } = await axios.request({
      method: "POST",
      url: requestUrl,
      data: rowData,
      headers: {
        Authorization: "Bearer " + token,
      },
      timeout: 10000 * 60, // Optional: Timeout of 10 seconds
    });

    return data;
  } catch (err: any) {
    console.log(err.message);
  }
};

export const makeServerRequestWithoutToken = async (
  controller: string,
  service: string,
  rowData: any
) => {
  const requestUrl = `${url}${controller}/${service}`;

  // Clearing the old auth token before making a request
  delete axios.defaults.headers.common.Authorization;

  try {
    try {
      const res = await axios.request({
        timeout: 1000 * 60,
        method: "POST",
        url: requestUrl,
        data: rowData,
        headers: {
          Authorization: "Bearer ",
        },
      });

      if (!res) {
        throw new Error("Error creating");
      }

      return res.data;
    } catch (err: any) {
      const error = JSON.parse(JSON.stringify(err));
      logDev(error);

      // Extract and display err message
      const errMessage =
        err.response?.data?.error || "An unknown error occurred";
      logDev(errMessage);

      // Rethrow the err to propagate it to the caller
      throw err;
    }
  } catch (err: any) {
    // Extract and display err message
    const errMessage = err.response?.data?.error || "An unknown error occurred";
    console.error("Request Error2: " + errMessage);
    logDev(errMessage);

    // Rethrow the err to propagate it to the caller
    throw err;
  }
};
