import React, { createContext, useContext, useState } from "react";

// Create the Toast Context
const ToastContext = createContext(null);

// Custom hook for easy access to the context
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = (content) => {
    const id = Date.now(); // Generate a unique ID
    setToasts((prevToasts) => [...prevToasts, { id, content }]);

    // Automatically remove the toast after 3 seconds
    setTimeout(() => {
      removeToast(id);
    }, 3000);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

// Example ToastContainer component
const ToastContainer = ({ toasts }) => (
  <div style={{ position: "fixed", top: 10, right: 10 }}>
    {toasts.map((toast) => (
      <div
        key={toast.id}
        style={{
          marginBottom: "8px",
          padding: "10px",
          background: "#333",
          color: "#fff",
          borderRadius: "4px",
        }}
      >
        {toast.content}
      </div>
    ))}
  </div>
);

export default ToastProvider;
