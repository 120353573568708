import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const StatutoryContribution = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    code: "",
    description: "",
    isActive: false,
    type: "PAYE Tax",
    brackets: [{ incomeMoreThan: "", percentage: "", cumulativeTax: "" }],
  });
  const [taxes] = useState<any[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  const handleToggleChange = () => {
    setFormData((prev) => ({ ...prev, isActive: !prev.isActive }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleAddTax = () => {
  //   setTaxes((prev) => [...prev, formData]);
  //   setFormData({
  //     amount: "",
  //     code: "",
  //     description: "",
  //     isActive: false,
  //     type: "",
  //     brackets: [],
  //   });
  //   handleClose();
  // };

  // const handleAddBracket = () => {
  //   setFormData({
  //     ...formData,
  //     brackets: [
  //       ...formData.brackets,
  //       { incomeMoreThan: "", percentage: "", cumulativeTax: "" },
  //     ],
  //   });
  // };

  // const handleRemoveBracket = (index: number) => {
  //   const updatedBrackets = formData.brackets.filter((_, i) => i !== index);
  //   setFormData({ ...formData, brackets: updatedBrackets });
  // };

  // const handleBracketChange = (index: number, field: string, value: string) => {
  //   const updatedBrackets = formData.brackets.map((bracket, i) =>
  //     i === index ? { ...bracket, [field]: value } : bracket
  //   );
  //   setFormData({ ...formData, brackets: updatedBrackets });
  // };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-lightgray py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Statutory Contribution</h3>
          </div>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IconButton
            onClick={handleOpen}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.map((tax, index) => (
              <TableRow key={index}>
                <TableCell>{tax.code}</TableCell>
                <TableCell>{tax.description}</TableCell>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.isActive ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal to add tax */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Statutory Tax</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Code Input Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Code"
                name="code"
                variant="outlined"
                value={formData.code}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Description Input Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                variant="outlined"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Active Toggle */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.isActive}
                    onChange={handleToggleChange}
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>

            <h3>Employer</h3>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Type"
                name="type"
                variant="outlined"
                value={formData.type}
                onChange={handleInputChange}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">Select Type</option>
                <option value="not applicable">Not Applicable</option>
                <option value="fixed amount">Fixed Amount</option>
                <option value="gross income">Gross Income</option>
                <option value="statutory table">Statutory Table</option>
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Amount"
                name="code"
                type="number"
                variant="outlined"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Capped Amount"
                name="code"
                type="number"
                variant="outlined"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Type"
                name="type"
                variant="outlined"
                value={formData.type}
                onChange={handleInputChange}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">Statutory Table</option>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {}} color="primary">
            Add Tax
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatutoryContribution;
