import { Button } from "@mui/material";
import { ChevronLeft } from "lucide-react";
import React from "react";
import IconButton from "../../components/Buttons/IconButton";
import CustomFormDialog from "../../components/Dialogs/CustomFormDialog";
import { COLORS } from "../../theme/theme";

const EmployeeCreationTabs = () => {
  const [open, setOpen] = React.useState(false);
  // const [selectedTab, setSelectedTab] = React.useState("personal");

  const handleToggleForm = () => {
    setOpen((prev) => !prev);
  };

  const handleSubmit = (formData: any) => {
    alert("Submitted Data: " + JSON.stringify(formData, null, 2));
    // Add API call or further processing here
  };

  const renderEmployeeTabs = () => {
    console.log("section");

    return (
      <div>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Hic velit est
        voluptas deserunt at sunt sint totam consequatur maiores vitae,
        provident aliquam consequuntur voluptatibus suscipit molestias
        consectetur, ipsa nemo beatae?
      </div>
    );
  };

  const renderHeaderSection = () => {
    return (
      <div>
        <div className="flex items-center">
          <IconButton icon={<ChevronLeft size={22} color={COLORS.black} />} />
          <h3>Header</h3>
        </div>
        <div className="flex items-center">
          <Button title="Hello WOrld" />
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleToggleForm}>
        Create New Employee
      </Button> */}
      <CustomFormDialog
        open={open}
        onClose={handleToggleForm}
        onSubmitFormData={handleSubmit}
        header={renderHeaderSection()}
        subHeader="Fill out the form below to place an employee"
      >
        {renderEmployeeTabs()}
      </CustomFormDialog>
    </div>
  );
};

export default EmployeeCreationTabs;
