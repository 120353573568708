import React, { useState } from "react";

const initialPolicy: LeavePolicy = {
  code: "",
  description: "",
  isActive: true,
  leaveUnit: "Day",
  dayCount: "Workday",
  color: "#008000",
  isPaidLeave: true,
  allowNegativeBalance: true,
  requireReason: true,
  requireAttachment: true,
  noticeDayCount: "Workday",
  rules: [
    { minDays: 0, advanceWorkdays: 0, isEnabled: true },
    { minDays: 0, advanceWorkdays: 0, isEnabled: false },
    { minDays: 0, advanceWorkdays: 0, isEnabled: false },
  ],
};

export interface LeavePolicy {
  code: string;
  description: string;
  isActive: boolean;
  leaveUnit: "Day" | "Hour";
  dayCount: "Workday" | "Calendar";
  color: string;
  isPaidLeave: boolean;
  allowNegativeBalance: boolean;
  requireReason: boolean;
  requireAttachment: boolean;
  noticeDayCount: "Workday" | "Calendar";
  rules: LeaveRule[];
}

export interface LeaveRule {
  minDays: number;
  advanceWorkdays: number;
  isEnabled: boolean;
}

export const LeaveForm: React.FC = () => {
  const [policy, setPolicy] = useState<LeavePolicy>(initialPolicy);

  const handleColorChange = (newColor: string) => {
    setPolicy((prevPolicy) => ({
      ...prevPolicy,
      color: newColor,
    }));
  };

  const ColorPicker = ({
    color,
    onChange,
  }: {
    color: string;
    onChange: (color: string) => void;
  }) => (
    <div className="w-full">
      <label className="block">
        <span className="text-sm font-medium text-gray-700">Pick a Color</span>
        <div className="flex items-center mt-2">
          {/* Color Preview Box */}
          <div
            className="w-8 h-8 rounded-full border shadow mr-2"
            style={{ backgroundColor: color }}
          />
          {/* Native Color Picker */}
          <input
            type="color"
            value={color}
            onChange={(e) => onChange(e.target.value)}
            className="cursor-pointer border-none outline-none"
          />
        </div>
      </label>
    </div>
  );

  const Toggle = ({
    label,
    checked,
    onChange,
    disabled = false,
  }: {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
  }) => (
    <label className="flex items-center justify-between p-3 cursor-pointer">
      <span className="text-sm font-medium text-gray-700">{label}</span>
      <button
        role="switch"
        aria-checked={checked}
        className={`
          relative inline-flex h-6 w-11 items-center rounded-full
          ${checked ? "bg-blue-600" : "bg-gray-200"}
          ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
        `}
        onClick={() => !disabled && onChange(!checked)}
        disabled={disabled}
      >
        <span
          className={`
            inline-block h-4 w-4 transform rounded-full bg-white transition
            ${checked ? "translate-x-6" : "translate-x-1"}
          `}
        />
      </button>
    </label>
  );

  const Select = ({
    label,
    value,
    onChange,
    options,
    disabled = false,
  }: {
    label: string;
    value: string;
    onChange: (value: string) => void;
    options: { label: string; value: string }[];
    disabled?: boolean;
  }) => (
    <div className="w-full">
      <label className="block">
        <span className="text-sm font-medium text-gray-700">{label}</span>
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          className="
            mt-1 block w-full rounded-md border-gray-300 shadow-sm
            focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50
            disabled:bg-gray-100 disabled:cursor-not-allowed
          "
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );

  const updateRule = (index: number, updates: Partial<LeaveRule>) => {
    setPolicy((prev) => ({
      ...prev,
      rules: prev.rules.map((rule, i) =>
        i === index ? { ...rule, ...updates } : rule
      ),
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 p-2">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow p-6 space-y-6">
        {/* Basic Information */}
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Code"
            value={policy.code}
            onChange={(e) =>
              setPolicy((prev) => ({ ...prev, code: e.target.value }))
            }
            maxLength={20}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
          />

          <input
            type="text"
            placeholder="Description"
            value={policy.description}
            onChange={(e) =>
              setPolicy((prev) => ({ ...prev, description: e.target.value }))
            }
            maxLength={50}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
          />

          <Toggle
            label="Active"
            checked={policy.isActive}
            onChange={(checked) =>
              setPolicy((prev) => ({ ...prev, isActive: checked }))
            }
          />

          <div className="grid grid-cols-2 gap-4">
            <Select
              label="Leave Unit"
              value={policy.leaveUnit}
              onChange={(value) =>
                setPolicy((prev) => ({
                  ...prev,
                  leaveUnit: value as "Day" | "Hour",
                }))
              }
              options={[
                { label: "Day", value: "Day" },
                { label: "Hour", value: "Hour" },
              ]}
            />

            <Select
              label="Day Count"
              value={policy.dayCount}
              onChange={(value) =>
                setPolicy((prev) => ({
                  ...prev,
                  dayCount: value as "Workday" | "Calendar",
                }))
              }
              options={[
                { label: "Workday", value: "Workday" },
                { label: "Calendar", value: "Calendar" },
              ]}
            />
          </div>

          <div className="p-4 border rounded">
            {/* Render the ColorPicker */}
            <ColorPicker color={policy.color} onChange={handleColorChange} />
            <p className="mt-4">
              Selected Color:{" "}
              <span
                className="px-2 py-1 rounded text-white"
                style={{ backgroundColor: policy.color }}
              >
                {policy.color}
              </span>
            </p>
          </div>

          <Toggle
            label="Paid Leave"
            checked={policy.isPaidLeave}
            onChange={(checked) =>
              setPolicy((prev) => ({ ...prev, isPaidLeave: checked }))
            }
          />
        </div>

        {/* Application Policy */}
        <div className="border rounded-lg p-4 space-y-4">
          <h2 className="text-lg font-semibold flex items-center gap-2">
            <i className="material-icons">rule</i>
            Application Policy
          </h2>

          <Toggle
            label="Allow Negative Balance"
            checked={policy.allowNegativeBalance}
            onChange={(checked) =>
              setPolicy((prev) => ({ ...prev, allowNegativeBalance: checked }))
            }
          />

          <Toggle
            label="Reason Required"
            checked={policy.requireReason}
            onChange={(checked) =>
              setPolicy((prev) => ({ ...prev, requireReason: checked }))
            }
          />

          <Toggle
            label="Attachment Required"
            checked={policy.requireAttachment}
            onChange={(checked) =>
              setPolicy((prev) => ({ ...prev, requireAttachment: checked }))
            }
          />
        </div>

        {/* Rules */}
        <div className="space-y-4">
          <h3 className="font-semibold">Rules</h3>

          {policy.rules.map((rule, index) => (
            <div key={index} className="flex items-center gap-4">
              <div className="w-8 h-8 rounded-full bg-orange-500 text-white flex items-center justify-center">
                {index}
              </div>

              {index === 0 ? (
                <div className="flex-1">For ALL leaves, apply ANYTIME.</div>
              ) : (
                <div className="flex-1 grid grid-cols-2 gap-4">
                  <input
                    type="number"
                    placeholder="For (X) Day Or More"
                    value={rule.minDays}
                    onChange={(e) =>
                      updateRule(index, { minDays: parseInt(e.target.value) })
                    }
                    disabled={!rule.isEnabled}
                    className="block w-full rounded-md border-gray-300 shadow-sm"
                  />
                  <input
                    type="number"
                    placeholder="Apply (Y) Workday In Advance"
                    value={rule.advanceWorkdays}
                    onChange={(e) =>
                      updateRule(index, {
                        advanceWorkdays: parseInt(e.target.value),
                      })
                    }
                    disabled={!rule.isEnabled}
                    className="block w-full rounded-md border-gray-300 shadow-sm"
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Warning Message */}
        <div className="bg-yellow-50 border-2 border-lime-500 rounded-lg p-4 mt-4">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <i className="material-icons text-lime-700">help_center</i>
            <span>
              Application Policy will only be enforced on employee submission,
              this will not affect submission by Administrator or HR Roles.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
