export const routings = {
  register: "/register",
  login: "/login",

  dashboard: "/dashboard",
  employee: {
    list: "/employee",
    create: "/management/employee-creation",
    edit: (id: string) => `/employee/edit/${id}`,
    management: "/management",
  },

  leave: {
    leaveTypes: "/leave/leave-types",
  },
  employer: {
    department: "/employer/departments",
    jobPositions: "/employer/job-positions",
    level: "/employer/level",
    modules: "/employer/modules",
  },

  payroll: {
    statutoryTable: "/payroll/statutory-table",
    statutoryContribution: "/payroll/statutory-contribution",
    bonus: "/payroll/bonus",
    deductions: "/payroll/deductions",
    earnings: "/payroll/earnings",
    annualSalaryReport: "/payroll/annual-salary-report",
    process: "/payroll/process-pay-roll",
    salaryAdjustment: "/payroll/salary-adjustment",
  },

  team: {
    discussion: "/team/discussions",
    documentSharing: "/team/document-sharing",
    announcements: "/team/announcement",
  },

  incident: {
    incident: "/incident/incident-management",
    incidentCategory: "/incident/incident-category",
  },
};
