import React from "react";
import { Navigate } from "react-router-dom";
// import HeaderStats from "./Headers/HeaderStats";
import { useSelector } from "react-redux";
import { RootState } from "../redux/stores/store";
import Sidebar from "./Sidebar/Sidebar";

// PrivateRoute wrapper
const PrivateRoute = ({ isPrivateRoute, component: Component }: any) => {
  const { user } = useSelector((state: RootState) => state.userData);

  // Check if the user is logged in
  if (!user.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* <AdminNavbar /> */}
        {/* <HeaderStats /> */}
        {/* <div className="px-4 md:px-10 mx-auto w-full -m-24"> */}
        <Component /> {/* Renders the matching child route */}
        {/* </div> */}
      </div>
    </>
  );
};

export default PrivateRoute;
