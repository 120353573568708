import { FormikValues, useFormik } from "formik";
import { User, Users } from "lucide-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomTextInput from "../../components/Inputs/CustomTextInput";
import Navbar from "../../components/Navbars/AuthNavbar";
import { useTitle } from "../../hooks/useTitle";
import { loginAction } from "../../redux/actions/authActions";
import { routings } from "../../routes/routings";
import { loginUser } from "../../services/authService";

const initialValues = {
  username: "",
  password: "",
};

export default function Login() {
  useTitle("Sign In");

  const dispatch = useDispatch();
  const [userType, setUserType] = useState({
    selected: true,
    type: "admin",
  });

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => login(values),
  });

  const login = async (values: FormikValues) => {
    try {
      const formData = {
        username: values.username,
        password: values.password,
      };

      const { returnObject, returnCode } = await loginUser(
        "auth",
        "login",
        JSON.stringify(formData)
      );

      if (returnCode === 200) {
        dispatch(loginAction(returnObject));
        console.log("Navigating to dashboard");

        navigate(routings.dashboard);
      } else {
        console.log("Login failed");
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  return (
    <>
      <Navbar absolute />

      <div className="mx-auto h-screen w-screen bg-blueGray-800">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in with
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className={`${
                      userType.type === "admin"
                        ? "bg-blueGray-800 text-white"
                        : "bg-white active:bg-blueGray-50 text-blueGray-700"
                    } px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150`}
                    onClick={() =>
                      setUserType({ selected: true, type: "admin" })
                    }
                  >
                    <User />
                    Admin login
                  </button>
                  <button
                    className={`${
                      userType.type === "employee"
                        ? "bg-blueGray-800 text-white"
                        : "bg-white active:bg-blueGray-50 text-blueGray-700"
                    } px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center justify-center font-bold text-xs ease-linear transition-all duration-150`}
                    onClick={() =>
                      setUserType({ selected: true, type: "employee" })
                    }
                  >
                    <Users />
                    Employee login
                  </button>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div>
                <form onSubmit={formik.handleSubmit} method="POST">
                  <div className="relative w-full mb-3">
                    <CustomTextInput
                      id="username"
                      name="username"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      placeholder="Username"
                      label="Username"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <CustomTextInput
                      id="password"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      placeholder="Enter Password"
                      label="Enter Password"
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
