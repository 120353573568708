import { Grid, TextField } from "@mui/material";
import React from "react";

type Props = {};

const PersonalForm = (props: Props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label>Date joined</label>
          <TextField
            required
            name="dob"
            id="dob"
            type="date"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <label>End of Probation</label>
          <TextField
            required
            name="dob"
            id="dob"
            type="date"
            fullWidth
            size="small"
          />
        </Grid>

        <h3>Placement</h3>

        <Grid item xs={12}>
          <label>Effective Date</label>
          <TextField
            required
            name="dob"
            id="dob"
            type="date"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <label>Job Position</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
              endAdornment: (
                <button
                  style={{
                    marginLeft: "8px",
                    padding: "4px 8px",
                    fontSize: "small",
                    cursor: "pointer",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  onClick={() => alert("Add Job Position")}
                >
                  Add
                </button>
              ),
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Line Manager</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Department</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
              endAdornment: (
                <button
                  style={{
                    marginLeft: "8px",
                    padding: "4px 8px",
                    fontSize: "small",
                    cursor: "pointer",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  onClick={() => alert("Add Job Position")}
                >
                  Add
                </button>
              ),
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Level</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
              endAdornment: (
                <button
                  style={{
                    marginLeft: "8px",
                    padding: "4px 8px",
                    fontSize: "small",
                    cursor: "pointer",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  onClick={() => alert("Add Job Position")}
                >
                  Add
                </button>
              ),
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <h3>Employment terms</h3>

        <Grid item xs={12}>
          <label>Effective Date</label>
          <TextField
            required
            name="dob"
            id="dob"
            type="date"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <label>Placement Type</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Job Status</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Leave Workflow</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
              endAdornment: (
                <button
                  style={{
                    marginLeft: "8px",
                    padding: "4px 8px",
                    fontSize: "small",
                    cursor: "pointer",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  onClick={() => alert("Add Job Position")}
                >
                  Add
                </button>
              ),
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>WorkDay</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
              endAdornment: (
                <button
                  style={{
                    marginLeft: "8px",
                    padding: "4px 8px",
                    fontSize: "small",
                    cursor: "pointer",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  onClick={() => alert("Add Job Position")}
                >
                  Add
                </button>
              ),
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <label>Holiday</label>
          <TextField
            select
            required
            name="jobPosition"
            id="jobPosition"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
              endAdornment: (
                <button
                  style={{
                    marginLeft: "8px",
                    padding: "4px 8px",
                    fontSize: "small",
                    cursor: "pointer",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  onClick={() => alert("Add Job Position")}
                >
                  Add
                </button>
              ),
            }}
          >
            <option value="" disabled>
              Select a Job Position
            </option>
            <option value="developer">Developer</option>
            <option value="designer">Designer</option>
            <option value="manager">Manager</option>
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default PersonalForm;
