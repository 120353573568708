import { useFormik } from "formik";
import {
  Briefcase,
  ChartNoAxesColumn,
  Check,
  ChevronLeft,
  CircleUser,
  Contact,
  EthernetPort,
  FastForward,
  FileDown,
  FileUp,
  Filter,
  HandCoins,
  Plus,
  ShieldPlus,
  User2,
  Users,
} from "lucide-react";
import * as React from "react";
import { useSelector } from "react-redux";
import IconButton from "../../components/Buttons/IconButton";
import CustomFormDialog from "../../components/Dialogs/CustomFormDialog";
import CustomSearchEngine from "../../components/Inputs/CustomSearchEngine";
import { RootState } from "../../redux/stores/store";
import { makeServerRequest } from "../../services/authService";
import { getRandomColor } from "../../services/CommonService";
import { COLORS } from "../../theme/theme";
import ContactForm from "./ContactForm";
import FamilyForm from "./FamilyForm";
import HealthCareForm from "./HealthCareForm";
import Job from "./Job";
import PersonalForm from "./PersonalForm";
import QuickEntryForm from "./QuickEntryForm";
import SalaryForm from "./SalaryForm";

type Props = {};
type Tab =
  | "quick entry"
  | "personal"
  | "job"
  | "salary"
  | "family"
  | "contact"
  | "health"
  | "directory"
  | "others";

const EmployeeManagement = (props: Props) => {
  const [employeeData, setEmployeeData] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("quick entry");
  // const [formData, setFormData] = React.useState({
  //   quickEntry: {},
  //   personal: {},
  //   job: {},
  //   salary: {},
  //   family: {},
  //   contact: {},
  //   health: {},
  //   others: {},
  // });

  const { user, jwtToken } = useSelector((state: RootState) => state.userData);
  const formik = useFormik({
    initialValues: {
      quickEntry: {},
      personal: {},
      job: {},
      salary: {},
      family: {},
      contact: {},
      health: {},
      others: {},
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleToggleForm = () => {
    setOpen((prev) => !prev);
  };

  React.useEffect(() => {
    let sub = true;

    // Fetch employee data from API or local storage
    if (sub) {
      fetchAllEmployeesOnUser();
    }

    return () => {
      sub = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllEmployeesOnUser = async () => {
    try {
      const { returnCode, returnObject } = await makeServerRequest(
        "management",
        "get-all-employees-on-user",
        JSON.stringify({ id: user.id }),
        jwtToken
      );

      if (returnCode === 200) {
        setEmployeeData(returnObject);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (formData: any) => {
    try {
      const { returnCode, returnObject } = await makeServerRequest(
        "management",
        "create-employee",
        JSON.stringify({ ...formData }),
        jwtToken
      );

      console.log(returnCode);

      if (returnCode === 200) {
        alert(JSON.stringify(returnObject));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const renderHeaderSection = () => {
    return (
      <div className="flex items-center justify-between border-b w-screen py-4 mb-8">
        <div className="flex items-center">
          <IconButton
            icon={<ChevronLeft size={22} color={COLORS.black} />}
            onClick={handleToggleForm}
          />
          <h3 className="text-lg font-semibold">Employee</h3>
        </div>
        <div className="flex items-center">
          <IconButton
            icon={<Check size={22} color={COLORS.success} />}
            onClick={() => formik.submitForm()}
            className="bg-green-600"
          />
        </div>
      </div>
    );
  };

  const handleTabChange = (tab: Tab) => {
    setSelectedTab(tab);
  };

  const TABS = [
    {
      label: "quick entry",
      icon: <FastForward size={22} />,
    },
    {
      label: "personal",
      icon: <User2 size={22} />,
    },
    {
      label: "job",
      icon: <Briefcase size={22} />,
    },
    {
      label: "salary",
      icon: <HandCoins size={22} />,
    },
    {
      label: "family",
      icon: <Users size={22} />,
    },
    {
      label: "contact",
      icon: <Contact size={22} />,
    },
    {
      label: "health",
      icon: <ShieldPlus size={22} />,
    },
    {
      label: "directory",
      icon: <Contact size={22} />,
    },
    {
      label: "others",
      icon: <EthernetPort size={22} />,
    },
  ];

  const renderEmployeeTabs = (): any => {
    return TABS.map((item: any, index: number) => {
      const isSelected = selectedTab === item.label;

      return (
        <div
          key={index}
          className={`flex relative bottom-0 border-r items-center justify-center py-4 cursor-pointer w-full space-x-3 px-4 transition-all duration-500 ease-in-out overflow-x-scroll`}
          style={{
            backgroundColor: isSelected ? getRandomColor() : undefined,
          }}
          onClick={() => handleTabChange(item.label)}
          title={item.label}
        >
          {/* Icon */}
          {item.icon}

          {/* Label */}
          {isSelected && (
            <h3 className="uppercase whitespace-nowrap ml-2 text-black text-base">
              {item.label}
            </h3>
          )}
        </div>
      );
    });
  };

  const renderBodySection = () => {
    switch (selectedTab) {
      case "quick entry":
        return (
          <div className="">
            <QuickEntryForm
              data={formik.values.quickEntry}
              onUpdate={(updatedValues) =>
                formik.setFieldValue("quickEntry", updatedValues)
              }
            />
          </div>
        );
      case "personal":
        return <PersonalForm />;
      case "job":
        return <Job />;
      case "salary":
        return <SalaryForm />;

      case "family":
        return <FamilyForm />;
      case "contact":
        return <ContactForm />;
      case "health":
        return <HealthCareForm />;
      case "directory":
        return (
          <div>
            <h3>Directories</h3>
          </div>
        );
      case "others":
        return (
          <div>
            <h3>Others</h3>
          </div>
        );
      default:
        return (
          <div className="">
            <h3 className="text-base font-bold text-black">Contact</h3>
          </div>
        );
    }
  };

  const handleViewEmployeeInfo = (employee: any) => () => {
    // Open employee info dialog with employee data
    console.log("View employee info:", employee);
    alert("Dialog to open");
  };
  return (
    <>
      <div className="w-screen bg-white px-4">
        <div className="w-full flex flex-col">
          <div className="flex justify-between px-4 py-5 border-b">
            <div
              className="shadow-lg p-2 flex items-center justify-center w-[2.5rem] h-[2.5rem] rounded-[50%] bg-purple-800 cursor-pointer"
              role="button"
              onClick={handleToggleForm}
            >
              <Plus size={32} color={COLORS.primary} />
            </div>
            <div>
              <CustomSearchEngine />
            </div>
          </div>
          <div className="py-6 px-4 flex flex-col">
            <div className="flex justify-between py-6 border-b">
              <div className="flex items-center">
                <h3 className="font-bold text-lg">Total:</h3>
                <h3 className="font-bold pl-3 text-lg">
                  {" "}
                  {employeeData.length > 0 && employeeData.length}
                </h3>
                <div className="px-4 cursor-pointer">
                  <Filter size={22} />
                </div>
              </div>
              <div className="flex items-center justify-between space-x-10">
                <FileUp size={25} className="cursor-pointer pr-2" />
                <FileDown size={25} className="cursor-pointer pl-2" />
              </div>
            </div>
            <div>
              {employeeData && employeeData.length > 0 ? (
                employeeData.map((employee: any, index) => (
                  <div
                    key={index}
                    role="button"
                    onClick={handleViewEmployeeInfo(employee)}
                    className="flex justify-between py-4 border-b hover:bg-blueGray-300 px-4 transition duration-300 ease-in-out cursor-pointer"
                  >
                    <div>
                      <CircleUser size={25} />
                    </div>
                    <div>{employee.employee_no}</div>
                    <div>{employee.employee_name}</div>
                    <div>{employee.email_address}</div>
                    <div>{employee.position}</div>
                  </div>
                ))
              ) : (
                <div className="text-center w-full h-auto flex flex-col justify-center items-center space-y-5">
                  <ChartNoAxesColumn color="lightgray" size={268} />
                  <div className="text-gray-500 text-3xl capitalize">
                    No employees found
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <CustomFormDialog
        open={open}
        onClose={handleToggleForm}
        onSubmitFormData={handleSubmit}
        header={renderHeaderSection()}
        subHeader="Fill out the form below to place an employee"
      >
        <div className="w-full h-full overflow-y-scroll">
          {renderBodySection()}
        </div>
        <div className="w-full border-t border-green-500 py-4 mt-16 flex justify-evenly items-center">
          {renderEmployeeTabs()}
        </div>
      </CustomFormDialog>
    </>
  );
};

export default EmployeeManagement;
