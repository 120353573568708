import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { AppProvider } from "./context/AppContext";
import ToastProvider from "./context/ToastContext";
import { useTitle } from "./hooks/useTitle";
import { store } from "./redux/stores/store";
import { appRoutes } from "./routes/routes";
import { routings } from "./routes/routings";
import Login from "./views/auth/Login";

const App = () => {
  useTitle("CanTreet Developers");
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppProvider>
          <ToastProvider>
            <Routes>
              <Route path={routings.login} element={<Login />} />
              {appRoutes.map(
                ({ Component, path, title, isPrivateRoute }, index) => {
                  document.title = title;
                  return (
                    <Route
                      key={index}
                      path={path}
                      element={
                        isPrivateRoute ? (
                          <PrivateRoute
                            isNotAdmin={isPrivateRoute}
                            component={Component}
                          />
                        ) : (
                          <Component />
                        )
                      }
                    />
                  );
                }
              )}
              {/* Handle 404 */}
              <Route
                path="*"
                Component={() => (
                  <div className="w-screen h-screen flex items-center justify-center">
                    <h3>Not Found</h3>
                  </div>
                )}
              />
            </Routes>
          </ToastProvider>
        </AppProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
