import { LOGIN_ADMIN_USER, LOGOUT } from "../types/authTypes";

export type InitialState = {
  isLoggedIn: boolean;
  jwtToken: string;
  refreshToken: string;
  user: any;
};

// interface LoginAction {
//   type: typeof LOGIN_ADMIN_USER;
//   payload: {
//     token: string;
//     refreshToken: string;
//     user: any;
//   };
// }

// interface LogoutAction {
//   type: typeof LOGOUT;
// }

const initialState: InitialState = {
  isLoggedIn: false,
  jwtToken: "",
  refreshToken: "",
  user: null,
};

const authReducer = (
  state: InitialState = initialState,
  { type, payload }: any
) => {
  // Implement your auth reducer logic here
  switch (type) {
    case LOGIN_ADMIN_USER:
      return {
        ...state,
        jwtToken: payload.token,
        // refreshToken: payload.refreshToken,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
