import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const Deductions = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    code: "",
    description: "",
    isActive: false,
    type: "PAYE Tax",
    brackets: [{ incomeMoreThan: "", percentage: "", cumulativeTax: "" }],
  });
  const [taxes] = useState<any[]>([]);

  const [taxEnabled, setTaxEnabled] = useState(true);
  const [otherStatutoryEnabled, setOtherStatutoryEnabled] = useState(true);

  const handleToggle = (type: "tax" | "otherStatutory") => {
    if (type === "tax") {
      setTaxEnabled(!taxEnabled);
    } else if (type === "otherStatutory") {
      setOtherStatutoryEnabled(!otherStatutoryEnabled);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleToggleChange = () => {
    setFormData((prev) => ({ ...prev, isActive: !prev.isActive }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-lightgray py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Deductions</h3>
          </div>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IconButton
            onClick={handleOpen}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.map((tax, index) => (
              <TableRow key={index}>
                <TableCell>{tax.code}</TableCell>
                <TableCell>{tax.description}</TableCell>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.isActive ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal to add tax */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Deductions</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Code Input Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Code"
                name="code"
                variant="outlined"
                value={formData.code}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Description Input Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                variant="outlined"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Active Toggle */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.isActive}
                    onChange={handleToggleChange}
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Amount"
                name="code"
                type="number"
                variant="outlined"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Quantity"
                name="code"
                type="number"
                variant="outlined"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Unit"
                name="code"
                type="text"
                variant="outlined"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <DialogTitle>Statutory Contribution</DialogTitle>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="body1">Tax</Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={taxEnabled}
                        onChange={() => handleToggle("tax")}
                        color="primary"
                      />
                    }
                    label=""
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="body1">Other Statutory</Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={otherStatutoryEnabled}
                        onChange={() => handleToggle("otherStatutory")}
                        color="primary"
                      />
                    }
                    label=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {}} color="primary">
            Add Deduction
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Deductions;
