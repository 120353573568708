import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

const AnnualSalaryReport = () => {
  const [taxes] = useState<any[]>([]);

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-lightgray py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Annual Salary Report</h3>
          </div>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.map((tax, index) => (
              <TableRow key={index}>
                <TableCell>{tax.code}</TableCell>
                <TableCell>{tax.description}</TableCell>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.isActive ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AnnualSalaryReport;
