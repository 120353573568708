import React from "react";

type Props = {
  icon: React.ReactNode;
  onClick?: () => void;
  className?: string;
  ariaLabel?: string;
};

const IconButton: React.FC<Props> = ({
  icon,
  onClick,
  className = "",
  ariaLabel = "icon button",
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      aria-label={ariaLabel}
      className={`flex items-center justify-center hover:!bg-coolGray-300 h-8 w-8 mr-2 rounded-full hover:opacity-[.6] cursor-pointer transition ease-in-out duration-200 ${className}`}
    >
      {icon}
    </button>
  );
};

export default IconButton;
