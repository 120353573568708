import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const SalaryAdjustment = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    code: "",
    description: "",
    currency: "",
    type: "PAYE Tax",
    brackets: [{ incomeMoreThan: "", percentage: "", cumulativeTax: "" }],
  });
  const [taxes] = useState<any[]>([]);

  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEarning, setOpenEarning] = useState(false);

  const handleOpenEarning = () => setOpenEarning(true);
  const handleCloseEarning = () => setOpenEarning(false);

  const [openDeductions, setOpenDeductions] = useState(false);

  const handleOpenDeductions = () => setOpenDeductions(true);
  const handleCloseDeductions = () => setOpenDeductions(false);

  const [openBonus, setOpenBonus] = useState(false);

  const handleOpenBonus = () => setOpenBonus(true);
  const handleCloseBonus = () => setOpenBonus(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <div className="w-full bg-lightgray py-2 px-3 rounded-lg flex items-center mt-3 justify-center bg-yellow-200">
            <h3>Salary Adjustments</h3>
          </div>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IconButton
            onClick={handleOpen}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.map((tax, index) => (
              <TableRow key={index}>
                <TableCell>{tax.code}</TableCell>
                <TableCell>{tax.description}</TableCell>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.isActive ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal to add tax */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Salary Adjusmtnet</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="select-employee-label">
                  Select Employee
                </InputLabel>
                <Select
                  labelId="select-employee-label"
                  name="currency"
                  value={formData.currency}
                  displayEmpty
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="Semi-Monthly">Semi-Monthly</MenuItem>
                  <MenuItem value="Bi-Weekly">Bi-Weekly</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Code Input Field */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Effective Date"
                type="date"
                name="code"
                variant="outlined"
                value={formData.code}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Amount"
                name="code"
                type="number"
                variant="outlined"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="select-currency-label">
                  Select Currency
                </InputLabel>
                <Select
                  labelId="select-currency-label"
                  name="currency"
                  value={formData.currency}
                  displayEmpty
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="Semi-Monthly">Semi-Monthly</MenuItem>
                  <MenuItem value="Bi-Weekly">Bi-Weekly</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Remarks"
                id="description"
                name="description"
                variant="outlined"
                value={formData.description}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </Grid>
            {/* Expansion Header */}
            <Grid item xs={12} className="q-expansion-item">
              <Grid
                container
                alignItems="center"
                onClick={handleExpandClick}
                sx={{
                  cursor: "pointer",
                  padding: "8px",
                  borderRadius: "8px",
                  backgroundColor: expanded ? "#e3f2fd" : "#f9f9f9",
                  "&:hover": { backgroundColor: "#bbdefb" },
                }}
              >
                {/* Toggle Icon */}
                <Grid item>
                  <IconButton>
                    <ExpandMoreIcon
                      sx={{
                        transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.2s",
                      }}
                    />
                  </IconButton>
                </Grid>

                {/* Title */}
                <Grid item xs>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#1976d2" }}
                  >
                    Earning
                  </Typography>
                </Grid>

                {/* Add Button */}
                <Grid item>
                  <IconButton
                    sx={{
                      backgroundColor: "#FFC107",
                      color: "#fff",
                      "&:hover": { backgroundColor: "#FFB300" },
                    }}
                    onClick={handleOpenEarning}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            {/* Expansion Content */}
            <Grid item xs={12}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      N/A
                    </Typography>
                  </CardContent>
                </Card>
              </Collapse>
            </Grid>

            {/* Expansion Header */}
            <Grid item xs={12} className="q-expansion-item">
              <Grid
                container
                alignItems="center"
                onClick={handleExpandClick}
                sx={{
                  cursor: "pointer",
                  padding: "8px",
                  borderRadius: "8px",
                  backgroundColor: expanded ? "#e3f2fd" : "#f9f9f9",
                  "&:hover": { backgroundColor: "#bbdefb" },
                }}
              >
                {/* Toggle Icon */}
                <Grid item>
                  <IconButton>
                    <ExpandMoreIcon
                      sx={{
                        transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.2s",
                      }}
                    />
                  </IconButton>
                </Grid>

                {/* Title */}
                <Grid item xs>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#1976d2" }}
                  >
                    Deductions
                  </Typography>
                </Grid>

                {/* Add Button */}
                <Grid item>
                  <IconButton
                    sx={{
                      backgroundColor: "#FFC107",
                      color: "#fff",
                      "&:hover": { backgroundColor: "#FFB300" },
                    }}
                    onClick={handleOpenDeductions}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            {/* Expansion Content */}
            <Grid item xs={12}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      N/A
                    </Typography>
                  </CardContent>
                </Card>
              </Collapse>
            </Grid>

            {/* Expansion Header */}
            <Grid item xs={12} className="q-expansion-item">
              <Grid
                container
                alignItems="center"
                onClick={handleExpandClick}
                sx={{
                  cursor: "pointer",
                  padding: "8px",
                  borderRadius: "8px",
                  backgroundColor: expanded ? "#e3f2fd" : "#f9f9f9",
                  "&:hover": { backgroundColor: "#bbdefb" },
                }}
              >
                {/* Toggle Icon */}
                <Grid item>
                  <IconButton>
                    <ExpandMoreIcon
                      sx={{
                        transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.2s",
                      }}
                    />
                  </IconButton>
                </Grid>

                {/* Title */}
                <Grid item xs>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#1976d2" }}
                  >
                    Bonus
                  </Typography>
                </Grid>

                {/* Add Button */}
                <Grid item>
                  <IconButton
                    sx={{
                      backgroundColor: "#FFC107",
                      color: "#fff",
                      "&:hover": { backgroundColor: "#FFB300" },
                    }}
                    onClick={handleOpenBonus}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            {/* Expansion Content */}
            <Grid item xs={12}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      N/A
                    </Typography>
                  </CardContent>
                </Card>
              </Collapse>
            </Grid>

            {/* Expansion Header */}
            <Grid item xs={12} className="q-expansion-item">
              <Grid
                container
                alignItems="center"
                onClick={handleExpandClick}
                sx={{
                  cursor: "pointer",
                  padding: "8px",
                  borderRadius: "8px",
                  backgroundColor: expanded ? "#e3f2fd" : "#f9f9f9",
                  "&:hover": { backgroundColor: "#bbdefb" },
                }}
              >
                {/* Toggle Icon */}
                <Grid item>
                  <IconButton>
                    <ExpandMoreIcon
                      sx={{
                        transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.2s",
                      }}
                    />
                  </IconButton>
                </Grid>

                {/* Title */}
                <Grid item xs>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#1976d2" }}
                  >
                    Statutory Contribution
                  </Typography>
                </Grid>

                {/* Add Button */}
                <Grid item>
                  <IconButton
                    sx={{
                      backgroundColor: "#FFC107",
                      color: "#fff",
                      "&:hover": { backgroundColor: "#FFB300" },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      alert("Add button clicked");
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      N/A
                    </Typography>
                  </CardContent>
                </Card>
              </Collapse>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {}} color="primary">
            Add Salary Adjustment
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEarning}
        onClose={handleCloseEarning}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Earnings</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Earning Input */}
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>
                  Earning
                  <i
                    className="material-icons"
                    style={{
                      fontSize: "10px",
                      color: "orange",
                      marginLeft: "5px",
                      verticalAlign: "text-top",
                    }}
                  >
                    star
                  </i>
                </InputLabel>
                <Select
                  label="Earning"
                  value="" // Add a state variable for managing the selected value
                  onChange={(e) => console.log(e.target.value)} // Update with actual handler
                  endAdornment={
                    <IconButton>
                      <AddCircleIcon color="action" />
                    </IconButton>
                  }
                >
                  <MenuItem value="earning1">Earning 1</MenuItem>
                  <MenuItem value="earning2">Earning 2</MenuItem>
                  <MenuItem value="earning3">Earning 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Amount Input */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                variant="filled"
                fullWidth
                disabled
                type="number"
              />
            </Grid>

            {/* Quantity Input */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Quantity"
                variant="filled"
                fullWidth
                disabled
                type="number"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeductions}
        onClose={handleCloseDeductions}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Deductions</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Earning Input */}
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>
                  Deduction
                  <i
                    className="material-icons"
                    style={{
                      fontSize: "10px",
                      color: "orange",
                      marginLeft: "5px",
                      verticalAlign: "text-top",
                    }}
                  >
                    star
                  </i>
                </InputLabel>
                <Select
                  label="Earning"
                  value="" // Add a state variable for managing the selected value
                  onChange={(e) => console.log(e.target.value)} // Update with actual handler
                  endAdornment={
                    <IconButton>
                      <AddCircleIcon color="action" />
                    </IconButton>
                  }
                >
                  <MenuItem value="earning1">Earning 1</MenuItem>
                  <MenuItem value="earning2">Earning 2</MenuItem>
                  <MenuItem value="earning3">Earning 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Amount Input */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                variant="filled"
                fullWidth
                disabled
                type="number"
              />
            </Grid>

            {/* Quantity Input */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Quantity"
                variant="filled"
                fullWidth
                disabled
                type="number"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDeductions} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleCloseDeductions}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openBonus}
        onClose={handleCloseEarning}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Bonus</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Earning Input */}
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>
                  Bonus
                  <i
                    className="material-icons"
                    style={{
                      fontSize: "10px",
                      color: "orange",
                      marginLeft: "5px",
                      verticalAlign: "text-top",
                    }}
                  >
                    star
                  </i>
                </InputLabel>
                <Select
                  label="Earning"
                  value="" // Add a state variable for managing the selected value
                  onChange={(e) => console.log(e.target.value)} // Update with actual handler
                  endAdornment={
                    <IconButton>
                      <AddCircleIcon color="action" />
                    </IconButton>
                  }
                >
                  <MenuItem value="earning1">Earning 1</MenuItem>
                  <MenuItem value="earning2">Earning 2</MenuItem>
                  <MenuItem value="earning3">Earning 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Amount Input */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                variant="filled"
                fullWidth
                disabled
                type="number"
              />
            </Grid>

            {/* Quantity Input */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Quantity"
                variant="filled"
                fullWidth
                disabled
                type="number"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseBonus} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleCloseBonus}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SalaryAdjustment;
